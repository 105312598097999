import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse as ReactCollapse } from 'react-collapse'
import Collapse from '@components/ui/collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories,
	requestSubCategories,
	documentStatusConfig
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import {
	PHONE_NUMBER_REGEX,
	POSTAL_CODE_REGEX
} from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import Notification from '@components/ui/notification'
import TextAreaInput from './inputs/textarea.inputs'
import CheckboxInput from './inputs/checkbox.input'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'

import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import AddressVoiePublicSection from '@components/ui/addressVoiePublicSection'
import MaskedThousandsSeparatorInput from './inputs/textWithThousandsSeparator.inputs'
import { formatNumberWithSpaces } from './__forms.utils'

const BuildingEnlargementPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	setDisableNextButton,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onSetCurrentSubStep,
	setStepsList
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		adresseBlock: string
		h5: string
		greyBackgroundBlock: string
		detailscontainer: string
		subContent: string
		reducedMarginBottom: string
		image: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},

		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},

		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			marginTop: '-15px',
			marginBottom: '15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px',
			'& div': {
				alignItems: 'start !important'
			}
		},
		h5: {
			fontSize: '20px',
			fontWeight: '600',
			margin: '15px 0 10px 0'
		},
		greyBackgroundBlock: {
			background: Colors.darkWhite,
			padding: '15px 30px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '50%43%',
			columnGap: '7%',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '45%46%',
				columnGap: '12%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important',
					width: '80% !important'
				}
			},
			[Breakpoints.maxWidth('sm')]: {
				'& img': {
					width: '100% !important'
				}
			},
			lineHeight: '1.62em',
			// fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		subContent: {
			display: 'grid',
			columnGap: '15px',
			rowGap: '10px',
			gridTemplateColumns: '2fr auto',
			alignItems: 'end',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '2fr auto'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '2fr auto'
			}
		},
		reducedMarginBottom: {
			marginBottom: '18px'
		},
		image: {
			[Breakpoints.maxWidth('md')]: {
				width: '35%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.buildingAddressSameHasApplicant?.toString() == 'false'
	)

	const [documeentCategory, setDocumentCategory] = useState<string>('')

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Type de demande ***********

	// Q1 Vous faites cette demande en tant que:
	const [requestAsInput, setRequestAsInput] = useState<RequestInputs>({
		name: 'requestAs',
		label: pageAssets?.request_form_request_as,
		labelKey: 'request_form_request_as',
		value: answers?.requestAs?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	// ************* Informations relatives à l'entreprise ***********

	// Nom de l'entreprise
	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.companyName || '',
		required:
			answers.requestAs?.toString() ==
				REQUEST_CONFIGS_KEYS.requestAs.entreprise ||
			answers.requestAs?.toString() ==
				REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Nom et prénom du représentant
	const [representativeNameInput, setRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'representativeName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_representativeName_label',
			value: answers.representativeName || '',
			required:
				answers.requestAs?.toString() ==
					REQUEST_CONFIGS_KEYS.requestAs.entreprise ||
				answers.requestAs?.toString() ==
					REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// Plan d'implantation et d'intégration architecturale (PIIA)

	// Est-ce que les travaux sont assujettis à une approbation PIIA?
	// Is the work subject to PIIA approval?
	const [isSubjectToPiiaApprovalInput, setIsSubjectToPiiaApprovalInput] =
		useState<RequestInputs>({
			name: 'isSubjectToPiiaApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description,
			value: answers.isSubjectToPiiaApproval?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Est-ce que le projet a fait l'objet d'une approbation PIIA?
	// Has the project been subject to PIIA approval?
	const [hasBeenSubjectToApprovalInput, setHasBeenSubjectToApprovalInput] =
		useState<RequestInputs>({
			name: 'hasBeenSubjectToApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label',
			value: answers.hasBeenSubjectToApproval?.toString() || '',
			required:
				answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// 	IA number (architectural integration)
	const [iaNumberInput, setIaNumberInput] = useState<RequestInputs>({
		name: 'iaNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_iaNumber_label',
		value: answers.iaNumber || '',
		required:
			answers.hasBeenSubjectToApproval?.toString() == radioListYesNo[0].value &&
			answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Executive Committee (EC) resolution number
	const [ecResolutionNumberInput, setEcResolutionNumberInput] =
		useState<RequestInputs>({
			name: 'ecResolutionNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label',
			value: answers.ecResolutionNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Support for promoters

	// Is the project currently the subject of a request for a “Real estate project served by municipal infrastructure”?
	const [isRealEstateProjectInput, setIsRealEstateProjectInput] =
		useState<RequestInputs>({
			name: 'isRealEstateProject',
			label:
				pageAssets?.request_form_buildingEnlargementPermit_isRealEstateProject_label,
			labelKey:
				'request_form_buildingEnlargementPermit_isRealEstateProject_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isRealEstateProject_description,
			value: answers.isRealEstateProject?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Project number served (PD)
	const [projectNumberInput, setProjectNumberInput] = useState<RequestInputs>({
		name: 'projectNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_projectNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_projectNumber_label',
		value: answers.projectNumber || '',
		required:
			answers.isRealEstateProject?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	// hidden input cadastre
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'buildingAddressSameHasApplicant',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSameLocation',
			value: answers?.buildingAddressSameHasApplicant?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		}
	]

	const [isLocationOwnedInput, setIsLocationOwnedInput] =
		useState<RequestInputs>({
			name: 'isLocationOwned',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
			labelKey: 'request_form_swimmingPoolSpaPermit_isLocationOwned',
			value: answers?.isLocationOwned?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//****** Owner inputs ******

	const [ownerFirstNameInput, setOwnerFirstNameInput] = useState<RequestInputs>(
		{
			name: 'ownerFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.ownerFirstName || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)
	const [ownerLastNameInput, setOwnerLastNameInput] = useState<RequestInputs>({
		name: 'ownerLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers.ownerLastName || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerEmailInput, setOwnerEmailInput] = useState<RequestInputs>({
		name: 'ownerEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.ownerEmail || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerPhoneNumberInput, setOwnerPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'ownerPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.ownerPhoneNumber || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Procuration du propriétaire
	// Owner's power of attorney
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_ownerPowerOfAttorney,
		labelKey: 'request_form_ownerPowerOfAttorney',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		description: '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.proxy
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_3 INPUTS -------------------
	//---------------------------------------------------------------

	const radioListMainUseEnlargementBuilding: RadioList[] =
		pageUtils.radioListMainUseEnlargementBuilding(pageAssets)

	// Quel est le type de structure?
	const [structureTypeInput, setStructureTypeInput] = useState<RequestInputs>({
		name: 'structureType',
		label: pageAssets?.request_form_buildingEnlargementPermit_structureType,
		labelKey: 'request_form_buildingEnlargementPermit_structureType',
		value: answers?.structureType?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const radioListStructureType: RadioList[] =
		pageUtils.radioListStructureType(pageAssets)

	//  "Quel est l'usage du bâtiment principal existant?"
	const [mainUseOfBuildingInput, setMainUseOfBuildingInput] =
		useState<RequestInputs>({
			name: 'mainUseOfExistingBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding',
			value: answers?.mainUseOfExistingBuilding?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Le bâtiment principal existant comporte-t-il de l'habitation ?
	const [mainBuildingHasHousingInput, setExistingMainBuildingHasHousingInput] =
		useState<RequestInputs>({
			name: 'mainBuildingHasHousing',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_existingMainBuildingHasHousing,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_existingMainBuildingHasHousing',
			value: answers?.mainBuildingHasHousing?.toString() || '',
			required:
				mainUseOfBuildingInput.value ==
				REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Le bâtiment principal existant est-il ou fait-il partie d'une copropriété?
	const [
		isTheBuildingPartOfAPropertyInput,
		setIsTheBuildingPartOfAPropertyInput
	] = useState<RequestInputs>({
		name: 'isTheNewBuildingPartOfAProperty',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty',
		value: answers?.isTheNewBuildingPartOfAProperty?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_4 INPUTS -------------------
	//---------------------------------------------------------------

	//-------------------- Informations sur le bâtiment INPUTS ------------------------

	// ----------------------------------------------------------------
	const [workTypeBuildingInput, setWorkTypeBuildingInput] =
		useState<RequestInputs>({
			name: 'workTypeBuilding',
			label: pageAssets?.request_form_buildingEnlargementPermit_workType,
			labelKey: 'request_form_buildingEnlargementPermit_workType',
			value: answers?.workTypeBuilding?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListWorkTypeEnlargementBuilding: CheckboxList[] =
		pageUtils.radioListWorkTypeEnlargementBuilding(
			pageAssets,
			mainBuildingHasHousingInput.value
		)

	// Measurement unit m² or ft²
	const radioListMeasurement2Unit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters2'
		},
		{
			label: pageAssets?.request_form_foot2,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'request_form_foot2'
		}
	]
	// Nombre de logements actuels
	const [workBuildingAreaInput, setWorkBuildingAreaInput] =
		useState<RequestInputs>({
			name: 'workBuildingArea',
			label: pageAssets?.request_form_buildingEnlargementPermit_enlargementArea,
			labelKey: 'request_form_buildingEnlargementPermit_enlargementArea',
			value: answers?.workBuildingArea?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [workBuildingAreaUnitInput, setBuildingAreaUnitInput] =
		useState<RequestInputs>({
			name: 'workBuildingAreaUnit',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingAreaUnit,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_buildingAreaUnit',
			value:
				answers?.workBuildingAreaUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})
	//number of dwelling added
	const [numberDwellingAddedInput, setNumberDwellingAddedInput] =
		useState<RequestInputs>({
			name: 'numberDwellingAdded',
			label:
				pageAssets?.request_form_buildingEnlargementPermit_enlargementNumberDwellingAdded,
			labelKey:
				'request_form_buildingEnlargementPermit_enlargementNumberDwellingAdded',
			value: answers?.numberDwellingAdded?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Nombre de logements
	const [currentNumberHomeInput, setCurrentNumberHomeInput] =
		useState<RequestInputs>({
			name: 'currentNumberHome',
			label:
				pageAssets?.request_form_buildingEnlargementPermit_enlargementCurrentNumberHome,
			labelKey:
				'request_form_buildingEnlargementPermit_enlargementCurrentNumberHome',
			value: answers?.currentNumberHome?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// ----------------------------------------------------------------

	// Des appareils de plomberie seront-ils installés au sous-sol dans la zone où les travaux auront lieu?
	const [plumbingWorkInput, setPlumbingWorkInput] = useState<RequestInputs>({
		name: 'plumbingWork',
		label:
			pageAssets?.request_form_buildingEnlargementPermit_enlargementPlumbingWork,
		labelKey: 'request_form_buildingEnlargementPermit_enlargementPlumbingWork',
		value: answers?.plumbingWork?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Indiquez le niveau de la dalle par rapport à la couronne de rue
	const [stabLevelInput, setStabLevelInput] = useState<RequestInputs>({
		name: 'stabLevelToStreet',
		label:
			pageAssets?.request_form_buildingEnlargementPermit_enlargementStabLevelToStreet,
		labelKey:
			'request_form_buildingEnlargementPermit_enlargementStabLevelToStreet',
		value: answers?.stabLevelToStreet?.toString() || '',
		required: plumbingWorkInput.value == radioListYesNo[0].value,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// ----------------------------------------------------------------
	//-------------------- Informations sur les travaux INPUTS ------------------------

	// -------------------  DETAILS_5 -------------------

	// Effectuez-vous ces travaux à la suite d'une catastrophe?
	const [isWorkDueToDisasterInput, setIsWorkDueToDisasterInput] =
		useState<RequestInputs>({
			name: 'isWorkDueToDisaster',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster',
			value: answers?.isWorkDueToDisaster?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Veuillez préciser WorkDueToDisasterPrecision
	const [workDueToDisasterPrecisionInput, setWorkDueToDisasterPrecisionInput] =
		useState<RequestInputs>({
			name: 'workDueToDisasterPrecision',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
			value: answers?.workDueToDisasterPrecision?.toString() || '',
			required: isWorkDueToDisasterInput.value == radioListYesNo[0].value,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Coût approximatif des travaux (pour la construction du bâtiment)
	const [approximateCostOfWorkInput, setApproximateCostOfWorkInput] =
		useState<RequestInputs>({
			name: 'approximateCostOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork',
			value: answers?.approximateCostOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Est-ce qu'un abattage d'arbres est requis?
	const [isTreeCuttingRequiredInput, setIsTreeCuttingRequiredInput] =
		useState<RequestInputs>({
			name: 'isTreeCuttingRequired',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired_description,
			value: answers?.isTreeCuttingRequired?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Renseignements supplémentaires
	const [additionalInfoInput, setAdditionalInfoInput] = useState<RequestInputs>(
		{
			name: 'additionalInfoQ28',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28_description,
			value: answers?.additionalInfoQ28?.toString() || '',
			required: false,
			ref: useRef<HTMLTextAreaElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		}
	)

	// Date de début (projetée)
	const [startDateInput, setStartDateInput] = useState<RequestInputs>({
		name: 'workStartDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_startDate',
		value: answers.workStartDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Date de fin (projetée)
	const [endDateInput, setEndDateInput] = useState<RequestInputs>({
		name: 'workEndDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_endDate',
		value: answers.workEndDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Entrepreneur exécutant les travaux de construction.

	// Qui sera en charge des travaux?
	const [whoWillBeInChargeOfWorkInput, setWhoWillBeInChargeOfWorkInput] =
		useState<RequestInputs>({
			name: 'whoWillBeInChargeOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork',
			value: answers?.whoWillBeInChargeOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		pageUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	// Je ne connais pas encore l'entrepreneur
	const [entrepreneurUnknownInput, setEntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown1',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown1?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom de l'entreprise exécutant les travaux de construction.
	const [companyNameExecutingWorkInput, setCompanyNameExecutingWorkInput] =
		useState<RequestInputs>({
			name: 'companyNameExecutingWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
			value: answers.companyNameExecutingWork || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Numéro d'entreprise du Québec (NEQ)
	const [companyNEQNumberInput, setCompanyNEQNumberInput] =
		useState<RequestInputs>({
			name: 'companyNEQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNEQNumber',
			value: answers.companyNEQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Numéro de licence RBQ
	const [companyRBQNumberInput, setCompanyRBQNumberInput] =
		useState<RequestInputs>({
			name: 'companyRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.companyRBQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [companyRepresentativeNameInput, setCompanyRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'companyRepresentativeName',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
			value: answers.companyRepresentativeName || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Téléphone
	const [
		companyRepresentativePhoneNumberInput,
		setCompanyRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.companyRepresentativePhoneNumber || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Courriel
	const [companyRepresentativeEmailInput, setCompanyRepresentativeEmailInput] =
		useState<RequestInputs>({
			name: 'companyRepresentativeEmail',
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.companyRepresentativeEmail || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Adresse de l'entreprise
	const [companyAddressInput, setCompanyAddressInput] = useState<RequestInputs>(
		{
			name: 'companyAddress',
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.companyAddress || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		}
	)

	// Ville
	const [companyCityInput, setCompanyCityInput] = useState<RequestInputs>({
		name: 'companyCity',
		label: pageAssets?.label_city,
		labelKey: 'label_city',
		value: answers.companyCity || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Code postal
	const [companyPostalCodeInput, setCompanyPostalCodeInput] =
		useState<RequestInputs>({
			name: 'companyPostalCode',
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.companyPostalCode || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	//----------------------------------------------------------------
	//----------------------- DETAILS_6 INPUTS -----------------------
	//----------------------------------------------------------------

	// Est-ce que des travaux de branchement d'aquéduc et d'égouts sont nécessaires à vos travaux ?
	const [
		isWaterAndSewerConnectionRequiredInput,
		setIsWaterAndSewerConnectionRequiredInput
	] = useState<RequestInputs>({
		name: 'isWaterAndSewerConnectionRequired',
		label:
			pageAssets?.request_form_buildingEnlargementPermit_isWaterAndSewerConnectionRequired,
		labelKey:
			'request_form_buildingEnlargementPermit_isWaterAndSewerConnectionRequired',
		value: answers.isWaterAndSewerConnectionRequired?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Votre lot est-il branché aux conduites principales municipales suivantes?
	const [
		isPropertyConnectedToMunicipalConduitsInput,
		setIsPropertyConnectedToMunicipalConduitsInput
	] = useState<RequestInputs>({
		name: 'isPropertyConnectedToMunicipalConduits',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits',
		value: answers.isPropertyConnectedToMunicipalConduits || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		pageUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	// Veuillez ajouter des précisions, si nécessaire
	const [Q42AdditionalInfoInput, setQ42AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ42',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42',
			value: answers.additionalInfoQ42 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Dans le cadre de vos travaux, prévoyez vous
	// As part of your work, you plan to
	const [plannedWorkInput, setPlannedWorkInput] = useState<RequestInputs>({
		name: 'plannedWork',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_plannedWork',
		value: answers.plannedWork || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	const listPlannedWork: CheckboxList[] = pageUtils.listPlannedWork(pageAssets)

	// Veuillez ajouter des précisions sur les travaux
	const [additionalInfoInput2, setAdditionalInfoInput2] =
		useState<RequestInputs>({
			name: 'additionalInfoQ44',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44',
			value: answers.additionalInfoQ44 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Je ne connais pas encore l'entrepreneur
	const [entrepreneurUnknownInput2, setEntrepreneurUnknownInput2] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	const isEntrepreneurFieldsRequired = (value) => {
		return [
			listPlannedWork[0].value,
			listPlannedWork[1].value,
			listPlannedWork[2].value,
			listPlannedWork[5].value,
			listPlannedWork[6].value
		].some((option) => value?.toString()?.includes(option))
	}

	// Nom de l'entreprise
	const [entrepreneurNameInput, setEntrepreneurNameInput] =
		useState<RequestInputs>({
			name: 'entrepreneurName',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
			value: answers.entrepreneurName || '',
			required:
				isEntrepreneurFieldsRequired(answers?.plannedWork) &&
				answers?.entrepreneurUnknown?.toString() == 'false',
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Numéro de licence RBQ
	const [entrepreneurRBQNumberInput, setEntrepreneurRBQNumberInput] =
		useState<RequestInputs>({
			name: 'entrepreneurRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.entrepreneurRBQNumber || '',
			required:
				isEntrepreneurFieldsRequired(answers?.plannedWork) &&
				answers?.entrepreneurUnknown?.toString() == 'false',
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		entrepreneurRepresentativeNameInput,
		setEntrepreneurRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.entrepreneurRepresentativeName || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Téléphone
	const [
		entrepreneurRepresentativePhoneNumberInput,
		setEntrepreneurRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.entrepreneurRepresentativePhoneNumber || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Courriel
	const [
		entrepreneurRepresentativeEmailInput,
		setEntrepreneurRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.entrepreneurRepresentativeEmail || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Adresse
	const [
		entrepreneurRepresentativeAddressInput,
		setEntrepreneurRepresentativeAddressInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeAddress',
		label: pageAssets?.label_address,
		labelKey: 'label_address',
		value: answers.entrepreneurRepresentativeAddress || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Ville
	const [
		entrepreneurRepresentativeCityInput,
		setEntrepreneurRepresentativeCityInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativeCity',
		label: pageAssets?.label_city,
		labelKey: 'label_city',
		value: answers.entrepreneurRepresentativeCity || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Code postal
	const [
		entrepreneurRepresentativePostalCodeInput,
		setEntrepreneurRepresentativePostalCodeInput
	] = useState<RequestInputs>({
		name: 'entrepreneurRepresentativePostalCode',
		label: pageAssets?.label_postalCode,
		labelKey: 'label_postalCode',
		value: answers.entrepreneurRepresentativePostalCode || '',
		required:
			isEntrepreneurFieldsRequired(answers?.plannedWork) &&
			answers?.entrepreneurUnknown?.toString() == 'false',
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// ----------------------------------------------------------------
	//////////////////////////////////////////////////////////////
	// --------------------- PA PERMIT INPUTS -----------------------------
	////////////////////////////////////////////////////////////////////

	// 11.1 Autres
	const [
		Q11_1WorkTypeBuildingPrecisionInput,
		setQ11_1WorkTypeBuildingPrecisionInput
	] = useState<RequestInputs>({
		name: 'workTypeBuildingPrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.workDueToDisasterPrecision?.toString() || '',
		required:
			mainUseOfBuildingInput.value.toString().includes(
			REQUEST_CONFIGS_KEYS.workTypeBuilding.seventhOption),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// ----------------------------------------------------------

	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// check if isIAApprovalTrue
	// to use value from summary answers or from inputForm.value

	const isIACategPermitDocument = (isformInputs?: boolean) => {
		if (isformInputs == true) {
			return isSubjectToPiiaApprovalInput.value == 'true' &&
				hasBeenSubjectToApprovalInput.value == 'true'
				? 'isIAApprovalTrue'
				: isSubjectToPiiaApprovalInput.value == 'true' &&
				  hasBeenSubjectToApprovalInput.value == 'false'
				? 'isIAApprovalFalse'
				: 'isNotIA'
		}

		return answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			answers?.hasBeenSubjectToApproval?.toString() == 'true'
			? 'isIAApprovalTrue'
			: answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			  answers?.hasBeenSubjectToApproval?.toString() == 'false'
			? 'isIAApprovalFalse'
			: 'isNotIA'
	}

	const getDocumentCategory = (
		type: string,
		category: string,
		isformInputs?: boolean
	) => {
		if (category == '') {
			return []
		}

		if (isIACategPermitDocument(isformInputs) == 'isIAApprovalTrue') {
			return documentStatusConfig.buildingEnlargementPermit[type][
				'isIAApprovalTrue'
			][category]
		}
		if (isIACategPermitDocument(isformInputs) == 'isIAApprovalFalse') {
			return documentStatusConfig.buildingEnlargementPermit[type][
				'isIAApprovalFalse'
			]
		}
		return documentStatusConfig.buildingEnlargementPermit[type]['isNotIA'][
			category
		]
	}

	const getDocumentStatus = (
		type: string,
		category: string | undefined,
		document: string,
		isformInputs?: boolean
	): string => {
		if (!category) {
			return 'not_required'
		}

		const categoryConfig = getDocumentCategory(type, category, isformInputs)
		if (categoryConfig) {
			return categoryConfig[document] || 'not_required'
		}
		return 'not_required'
	}

	const isDocumentToShow = (
		type: string,
		category: string | undefined,
		document: string,
		status: string,
		isformInputs?: boolean
	) => {
		return getDocumentStatus(type, category, document, isformInputs) == status
	}

	// Plan of implantation
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label: pageAssets?.request_form_buildingEnlargementPermit_implantationPlan,
		labelKey: 'request_form_buildingEnlargementPermit_implantationPlan',
		description:
			pageAssets?.request_form_buildingEnlargementPermit_connectionSheet_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'secondInput', true) ==
			'required',
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1
	})

	// Plan d'architecture
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_architecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_architecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'thirdInput', true) ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'aménagement
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_developmentPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_developmentPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_developmentPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'fourthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan mécanique
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_mechanicalPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_mechanicalPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'fifthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan électrique
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_electricalPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_electricalPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'sixthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de ventilation
	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_ventilationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_ventilationPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'seventhInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan civil
	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_civilPlan,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_civilPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'eighthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan de structure
	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_structuralPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_structuralPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'ninthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Fiche Bâtiment/Fiche Technique*
	const [tenthQuestion, setTenthQuestion] = useState<RequestInputFiles>({
		name: 'tenthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingSheet,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_buildingSheet',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'tenthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'tenthInput') ==
			'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputFiles>({
		name: 'eleventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eleventhInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'eleventhInput') ==
			'required',
		numberMaxFiles: 10,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	//Plan d'architecture préliminaire
	const [twelfthQuestion, setTwelfthQuestion] = useState<RequestInputFiles>({
		name: 'twelfthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'twelfthInput')?.files || []
		),
		required:
			getDocumentStatus('DEFAULT', documeentCategory, 'twelfthInput') ==
			'required',
		numberMaxFiles: 10,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Étude acoustique
	const [thirteenthQuestion, setThirteenthQuestion] =
		useState<RequestInputFiles>({
			name: 'thirteenthInput',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_acousticStudy,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_acousticStudy',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'thirteenthInput')?.files || []
			),
			required:
				getDocumentStatus('DEFAULT', documeentCategory, 'thirteenthInput') ==
				'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	// Plan d'aménagement paysager (préliminaire)
	const [fourteenthQuestion, setFourteenthQuestion] =
		useState<RequestInputFiles>({
			name: 'fourteenthInput',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_landscapePlan',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'fourteenthInput')?.files || []
			),
			required:
				getDocumentStatus('DEFAULT', documeentCategory, 'fourteenthInput') ==
				'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	//Plan d'ingénieur
	const [fifteenthQuestion, setFifteenthQuestion] = useState<RequestInputFiles>(
		{
			name: 'fifteenthInput',
			label: pageAssets?.request_form_buildingEnlargementPermit_engineerPlan,
			labelKey: 'request_form_buildingEnlargementPermit_engineerPlan',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_landscapePlan_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'fifteenthInput')?.files || []
			),
			required:
				getDocumentStatus('DEFAULT', documeentCategory, 'fifteenthInput') ==
				'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		}
	)

	//Certificat de localisation
	const [sixteenthQuestion, setSixteenthQuestion] = useState<RequestInputFiles>(
		{
			name: 'sixteenthInput',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_locationCertificate,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_locationCertificate',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'sixteenthInput')?.files || []
			),
			required:
				getDocumentStatus('DEFAULT', documeentCategory, 'sixteenthInput') ==
				'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		}
	)

	//Fiche Branchement
	const [seventeenthQuestion, setSeventeenthQuestion] =
		useState<RequestInputFiles>({
			name: 'seventeenthInput',
			label: pageAssets?.request_form_buildingEnlargementPermit_connectionSheet,
			labelKey: 'request_form_buildingEnlargementPermit_connectionSheet',
			description: '',
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'seventeenthInput')?.files || []
			),
			required:
				getDocumentStatus('DEFAULT', documeentCategory, 'seventeenthInput') ==
				'required',
			numberMaxFiles: 10,
			category: requestCategories.other,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
			shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	// Reusable function to set input and handle errors
	const handleInputChange = (setInput, input, value, inputName) => {
		setInput({
			...input,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, inputName))
	}

	// Reusable hook to update inputs
	const useUpdateInputs = (input) => {
		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, input))
		}, [input])
	}

	//------------------------------------------------------------

	const onSetRequestAsInput = (value: string) => {
		setRequestAsInput({ ...requestAsInput, value })

		if (
			value == REQUEST_CONFIGS_KEYS.requestAs.entreprise ||
			value == REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
		) {
			setCompanyNameInput({ ...companyNameInput, required: true })
			setRepresentativeNameInput({ ...representativeNameInput, required: true })
		} else {
			setCompanyNameInput({ ...companyNameInput, required: false })
			setRepresentativeNameInput({
				...representativeNameInput,
				required: false
			})
		}

		if (
			value == REQUEST_CONFIGS_KEYS.requestAs.individual &&
			whoWillBeInChargeOfWorkInput.value ==
				REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.firstOption
		) {
			setWhoWillBeInChargeOfWorkInput({
				...whoWillBeInChargeOfWorkInput,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						requestAsInput.name,
						companyNameInput.name,
						representativeNameInput.name,
						companyNameExecutingWorkInput.name,
						companyNEQNumberInput.name,
						companyRBQNumberInput.name,
						companyRepresentativeNameInput.name,
						companyRepresentativePhoneNumberInput.name,
						companyRepresentativeEmailInput.name,
						companyAddressInput.name,
						companyCityInput.name,
						companyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(requestAsInput)

	//------------------------------------------------------------

	const onSetCompanyNameInput = (value: string) => {
		handleInputChange(
			setCompanyNameInput,
			companyNameInput,
			value,
			companyNameInput.name
		)
	}

	useUpdateInputs(companyNameInput)

	//------------------------------------------------------------
	const onSetRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setRepresentativeNameInput,
			representativeNameInput,
			value,
			representativeNameInput.name
		)
	}

	useUpdateInputs(representativeNameInput)

	//------------------------------------------------------------

	const onSetIsSubjectToPiiaApprovalInput = (value: string) => {
		setIsSubjectToPiiaApprovalInput({ ...isSubjectToPiiaApprovalInput, value })

		if (value == radioListYesNo[0].value) {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: true
			})
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: false
			})
			setIaNumberInput({ ...iaNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isSubjectToPiiaApprovalInput)

	//------------------------------------------------------------

	const onSetHasBeenSubjectToApprovalInput = (value: string) => {
		setHasBeenSubjectToApprovalInput({
			...hasBeenSubjectToApprovalInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setIaNumberInput({ ...iaNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![hasBeenSubjectToApprovalInput.name, iaNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(hasBeenSubjectToApprovalInput)

	//------------------------------------------------------------

	useEffect(() => {
		updateRequiredDocuments('')
		setTimeout(() => {
			updateRequiredDocuments(documeentCategory, true)
		}, 100)
	}, [
		isSubjectToPiiaApprovalInput,
		hasBeenSubjectToApprovalInput,
		numberDwellingAddedInput,
		currentNumberHomeInput
	])

	//------------------------------------------------------------

	const onSetIaNumberInput = (value: string) => {
		handleInputChange(
			setIaNumberInput,
			iaNumberInput,
			value.toLocaleUpperCase(),
			iaNumberInput.name
		)
	}

	useUpdateInputs(iaNumberInput)

	//------------------------------------------------------------

	const onSetEcResolutionNumberInput = (value: string) => {
		handleInputChange(
			setEcResolutionNumberInput,
			ecResolutionNumberInput,
			value.toLocaleUpperCase(),
			ecResolutionNumberInput.name
		)
	}

	useUpdateInputs(ecResolutionNumberInput)

	//------------------------------------------------------------

	const onSetIsRealEstateProjectInput = (value: string) => {
		setIsRealEstateProjectInput({ ...isRealEstateProjectInput, value })

		if (value == radioListYesNo[0].value) {
			setProjectNumberInput({ ...projectNumberInput, required: true })
		} else {
			setProjectNumberInput({ ...projectNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![isRealEstateProjectInput.name, projectNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(isRealEstateProjectInput)

	//------------------------------------------------------------

	const onSetProjectNumberInput = (value: string) => {
		handleInputChange(
			setProjectNumberInput,
			projectNumberInput,
			value.toLocaleUpperCase(),
			projectNumberInput.name
		)
	}

	useUpdateInputs(projectNumberInput)

	//------------------------------------------------------------

	const getCadastralNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})
		})
	}

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (coordinates.validate()) {
			setIsAddressOutsideLaval(false)
			getCadastralNumber(coordinates)
		} else {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	const onSetIsSameLocationInput = (value: string) => {
		setIsSameLocationInput({ ...isSameLocationInput, value })

		setDisableNextButton && setDisableNextButton(true)

		if (value == radioListYesNo[0].value) {
			if (isAddressOutsideLaval) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSameLocationInput.name)
		)
	}

	useUpdateInputs(isSameLocationInput)

	useEffect(() => {
		if (
			subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
			isAddressOutsideLaval &&
			isSameLocationInput.value == radioListYesNo[0].value
		) {
			setDisableNextButton && setDisableNextButton(false)
		}
	}, [subSteps?.position])
	//------------------------------------------------------------

	const setRequiredOwnerInfoInputs = (value: boolean) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, required: value })
		setOwnerLastNameInput({ ...ownerLastNameInput, required: value })
		setOwnerEmailInput({ ...ownerEmailInput, required: value })
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, required: value })
		setFirstQuestion({ ...firstQuestion, required: value })
	}

	const onSetIsLocationOwnedInput = (value: string) => {
		setIsLocationOwnedInput({ ...isLocationOwnedInput, value })

		if (value == radioListYesNo[1].value) {
			setRequiredOwnerInfoInputs(true)
		} else {
			setRequiredOwnerInfoInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationOwnedInput.name,
						ownerFirstNameInput.name,
						ownerLastNameInput.name,
						ownerEmailInput.name,
						ownerPhoneNumberInput.name,
						firstQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isLocationOwnedInput)
	//------------------------------------------------------------

	const onSetOwnerFirstNameInput = (value: string) => {
		handleInputChange(
			setOwnerFirstNameInput,
			ownerFirstNameInput,
			value,
			ownerFirstNameInput.name
		)
	}

	useUpdateInputs(ownerFirstNameInput)
	//------------------------------------------------------------

	const onSetOwnerLastNameInput = (value: string) => {
		handleInputChange(
			setOwnerLastNameInput,
			ownerLastNameInput,
			value,
			ownerLastNameInput.name
		)
	}

	useUpdateInputs(ownerLastNameInput)

	//------------------------------------------------------------

	const onSetOwnerEmailInput = (value: string) => {
		handleInputChange(
			setOwnerEmailInput,
			ownerEmailInput,
			value,
			ownerEmailInput.name
		)
	}

	useUpdateInputs(ownerEmailInput)
	//------------------------------------------------------------

	const onSetOwnerPhoneNumberInput = (value: string) => {
		handleInputChange(
			setOwnerPhoneNumberInput,
			ownerPhoneNumberInput,
			value,
			ownerPhoneNumberInput.name
		)
	}

	useUpdateInputs(ownerPhoneNumberInput)
	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 3 ---------------------------
	// ----------------------------------------------------------------
	const updateRequiredDocuments = (
		mainUseOfBuilding: string | undefined,
		isformInputs?: boolean
	) => {
		const category = getDocumentCategory(
			'DEFAULT',
			documeentCategory ?? '',
			isformInputs
		)

		setSecondQuestion({
			...secondQuestion,
			required: category?.secondInput == 'required'
		})

		setTwelfthQuestion({
			...twelfthQuestion,
			required: category?.twelfthInput == 'required'
		})

		setThirteenthQuestion({
			...thirteenthQuestion,
			required: category?.thirteenthInput == 'required'
		})

		setFourteenthQuestion({
			...fourteenthQuestion,
			required: category?.fourteenthInput == 'required'
		})

		setThirdQuestion({
			...thirdQuestion,
			required: category?.thirdInput == 'required'
		})
		setFourthQuestion({
			...fourthQuestion,
			required: category?.fourthInput == 'required'
		})
		setFifthQuestion({
			...fifthQuestion,
			required: category?.fifthInput == 'required'
		})
		setSixthQuestion({
			...sixthQuestion,
			required: category?.sixthInput == 'required'
		})
		setSeventhQuestion({
			...seventhQuestion,
			required: category?.seventhInput == 'required'
		})
		setEighthQuestion({
			...eighthQuestion,
			required: category?.eighthInput == 'required'
		})
		setNinthQuestion({
			...ninthQuestion,
			required: category?.ninthInput == 'required'
		})
		setTenthQuestion({
			...tenthQuestion,
			required: category?.tenthInput == 'required'
		})
		setEleventhQuestion({
			...eleventhQuestion,
			required: category?.eleventhInput == 'required'
		})
		setSixteenthQuestion({
			...sixteenthQuestion,
			required: category?.sixteenthInput == 'required'
		})
		setFifteenthQuestion({
			...fifteenthQuestion,
			required: category?.fifteenthInput == 'required'
		})
		setSeventeenthQuestion({
			...seventeenthQuestion,
			required: category?.seventeenthInput == 'required'
		})
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, currentNumberHomeInput))
	}, [currentNumberHomeInput])

	//------------------------------------------------------------

	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 4 ---------------------------
	// ----------------------------------------------------------------

	const onSetStructureTypeInput = (value: string) => {
		handleInputChange(
			setStructureTypeInput,
			structureTypeInput,
			value,
			structureTypeInput.name
		)
	}

	useUpdateInputs(structureTypeInput)

	//------------------------------------------------------------

	const onSetWorkBuildingAreaInput = (value: string) => {
		handleInputChange(
			setWorkBuildingAreaInput,
			workBuildingAreaInput,
			value,
			workBuildingAreaInput.name
		)
	}

	useUpdateInputs(workBuildingAreaInput)

	//------------------------------------------------------------
	const onSetBuildingAreaUnitInput = (value: string) => {
		handleInputChange(
			setBuildingAreaUnitInput,
			workBuildingAreaUnitInput,
			value,
			workBuildingAreaUnitInput.name
		)
	}

	useUpdateInputs(workBuildingAreaUnitInput)

	//------------------------------------------------------------

	const onSetNumberDwellingAddedInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 6) {
			return
		}
		handleInputChange(
			setNumberDwellingAddedInput,
			numberDwellingAddedInput,
			value,
			numberDwellingAddedInput.name
		)
		updateRequiredDocuments(documeentCategory, true)
	}

	useUpdateInputs(numberDwellingAddedInput)

	//------------------------------------------------------------

	const onSetCurrentNumberHomeInput = (value: string) => {
		if (value?.toString()?.replace(/[.,]/g, '')?.length > 6) {
			return
		}
		handleInputChange(
			setCurrentNumberHomeInput,
			currentNumberHomeInput,
			value,
			currentNumberHomeInput.name
		)

		updateRequiredDocuments(documeentCategory, true)
	}

	useUpdateInputs(currentNumberHomeInput)

	//------------------------------------------------------------

	const onSetPlumbingWorkInput = (value: string) => {
		setPlumbingWorkInput({
			...plumbingWorkInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setStabLevelInput({
				...stabLevelInput,
				required: true
			})
		} else {
			setStabLevelInput({
				...stabLevelInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![plumbingWorkInput.name, stabLevelInput.name].includes(error)
			)
		)
	}

	useUpdateInputs(plumbingWorkInput)

	//------------------------------------------------------------

	const onSetStabLevelInput = (value: string) => {
		handleInputChange(
			setStabLevelInput,
			stabLevelInput,
			value,
			stabLevelInput.name
		)
	}

	useUpdateInputs(stabLevelInput)

	//------------------------------------------------------------

	const onSetIsWorkDueToDisasterInput = (value: string) => {
		setIsWorkDueToDisasterInput({
			...isWorkDueToDisasterInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setWorkDueToDisasterPrecisionInput({
				...workDueToDisasterPrecisionInput,
				required: true
			})
		} else {
			setWorkDueToDisasterPrecisionInput({
				...workDueToDisasterPrecisionInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isWorkDueToDisasterInput.name,
						workDueToDisasterPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isWorkDueToDisasterInput)

	//------------------------------------------------------------

	const onSetWorkDueToDisasterPrecisionInput = (value: string) => {
		handleInputChange(
			setWorkDueToDisasterPrecisionInput,
			workDueToDisasterPrecisionInput,
			value,
			workDueToDisasterPrecisionInput.name
		)
	}

	useUpdateInputs(workDueToDisasterPrecisionInput)

	//------------------------------------------------------------

	const onSetApproximateCostOfWorkInput = (value) => {
		// const rawValue = value?.toString().replace(/ /g, '')
		handleInputChange(
			setApproximateCostOfWorkInput,
			approximateCostOfWorkInput,
			value,
			approximateCostOfWorkInput.name
		)
	}

	useUpdateInputs(approximateCostOfWorkInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetIsTreeCuttingRequiredInput = (value: string) => {
		handleInputChange(
			setIsTreeCuttingRequiredInput,
			isTreeCuttingRequiredInput,
			value,
			isTreeCuttingRequiredInput.name
		)
	}

	useUpdateInputs(isTreeCuttingRequiredInput)

	//------------------------------------------------------------

	const onSetAdditionalInfoInput = (value: string) => {
		handleInputChange(
			setAdditionalInfoInput,
			additionalInfoInput,
			value,
			additionalInfoInput.name
		)
	}

	useUpdateInputs(additionalInfoInput)

	//------------------------------------------------------------

	const onSetStartDateInput = (value: string) => {
		handleInputChange(
			setStartDateInput,
			startDateInput,
			value,
			startDateInput.name
		)
	}

	useUpdateInputs(startDateInput)

	//------------------------------------------------------------

	const onSetEndDateInput = (value: string) => {
		handleInputChange(setEndDateInput, endDateInput, value, endDateInput.name)
	}

	useUpdateInputs(endDateInput)

	//------------------------------------------------------------

	const setCompanyInputsRequired = (value: boolean) => {
		setCompanyNameExecutingWorkInput({
			...companyNameExecutingWorkInput,
			required: value
		})
		setCompanyNEQNumberInput({
			...companyNEQNumberInput,
			required: value
		})
		setCompanyRBQNumberInput({
			...companyRBQNumberInput,
			required: value
		})
		setCompanyRepresentativeNameInput({
			...companyRepresentativeNameInput,
			required: value
		})
		setCompanyRepresentativePhoneNumberInput({
			...companyRepresentativePhoneNumberInput,
			required: value
		})
		setCompanyRepresentativeEmailInput({
			...companyRepresentativeEmailInput,
			required: value
		})
		setCompanyAddressInput({
			...companyAddressInput,
			required: value
		})
		setCompanyCityInput({
			...companyCityInput,
			required: value
		})
		setCompanyPostalCodeInput({
			...companyPostalCodeInput,
			required: value
		})
	}

	const onSetWhoWillBeInChargeOfWorkInput = (value: string) => {
		setWhoWillBeInChargeOfWorkInput({
			...whoWillBeInChargeOfWorkInput,
			value
		})

		setEntrepreneurUnknownInput({
			...entrepreneurUnknownInput,
			value: 'false'
		})

		if (value == radioListWhoWillBeInChargeOfWork[0].value) {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						whoWillBeInChargeOfWorkInput.name,
						companyNameExecutingWorkInput.name,
						companyNEQNumberInput.name,
						companyRBQNumberInput.name,
						companyRepresentativeNameInput.name,
						companyRepresentativePhoneNumberInput.name,
						companyRepresentativeEmailInput.name,
						companyAddressInput.name,
						companyCityInput.name,
						companyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(whoWillBeInChargeOfWorkInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurUnknownInput = (value: string) => {
		setEntrepreneurUnknownInput({
			...entrepreneurUnknownInput,
			value
		})

		if (value?.toString() == 'true') {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						companyNameExecutingWorkInput.name,
						companyNEQNumberInput.name,
						companyRBQNumberInput.name,
						companyRepresentativeNameInput.name,
						companyRepresentativePhoneNumberInput.name,
						companyRepresentativeEmailInput.name,
						companyAddressInput.name,
						companyCityInput.name,
						companyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(entrepreneurUnknownInput)

	//------------------------------------------------------------

	const onSetCompanyNameExecutingWorkInput = (value: string) => {
		handleInputChange(
			setCompanyNameExecutingWorkInput,
			companyNameExecutingWorkInput,
			value,
			companyNameExecutingWorkInput.name
		)
	}

	useUpdateInputs(companyNameExecutingWorkInput)

	//------------------------------------------------------------

	const onSetCompanyNEQNumberInput = (value: string) => {
		handleInputChange(
			setCompanyNEQNumberInput,
			companyNEQNumberInput,
			value,
			companyNEQNumberInput.name
		)
	}

	useUpdateInputs(companyNEQNumberInput)
	//------------------------------------------------------------

	const onSetCompanyRBQNumberInput = (value: string) => {
		handleInputChange(
			setCompanyRBQNumberInput,
			companyRBQNumberInput,
			value,
			companyRBQNumberInput.name
		)
	}

	useUpdateInputs(companyRBQNumberInput)
	//------------------------------------------------------------

	const onSetCompanyRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setCompanyRepresentativeNameInput,
			companyRepresentativeNameInput,
			value,
			companyRepresentativeNameInput.name
		)
	}

	useUpdateInputs(companyRepresentativeNameInput)
	//------------------------------------------------------------
	const onSetCompanyRepresentativePhoneNumberInput = (value: string) => {
		handleInputChange(
			setCompanyRepresentativePhoneNumberInput,
			companyRepresentativePhoneNumberInput,
			value,
			companyRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(companyRepresentativePhoneNumberInput)
	//------------------------------------------------------------

	const onSetCompanyRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setCompanyRepresentativeEmailInput,
			companyRepresentativeEmailInput,
			value,
			companyRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(companyRepresentativeEmailInput)
	//------------------------------------------------------------

	const onSetCompanyAddressInput = (value: string) => {
		handleInputChange(
			setCompanyAddressInput,
			companyAddressInput,
			value,
			companyAddressInput.name
		)
	}

	useUpdateInputs(companyAddressInput)
	//------------------------------------------------------------

	const onSetCompanyCityInput = (value: string) => {
		handleInputChange(
			setCompanyCityInput,
			companyCityInput,
			value,
			companyCityInput.name
		)
	}

	useUpdateInputs(companyCityInput)
	//------------------------------------------------------------
	const onSetCompanyPostalCodeInput = (value: string) => {
		handleInputChange(
			setCompanyPostalCodeInput,
			companyPostalCodeInput,
			value?.toUpperCase(),
			companyPostalCodeInput.name
		)
	}

	useUpdateInputs(companyPostalCodeInput)
	//------------------------------------------------------------

	const onSetIsWaterAndSewerConnectionRequiredInput = (value: string) => {
		setIsWaterAndSewerConnectionRequiredInput({
			...isWaterAndSewerConnectionRequiredInput,
			value: value
		})

		if (value == radioListYesNo[1].value) {
			setIsPropertyConnectedToMunicipalConduitsInput({
				...isPropertyConnectedToMunicipalConduitsInput,
				value: ''
			})
			setPlannedWorkInput({
				...plannedWorkInput,
				value: ''
			})
			setAdditionalInfoInput2({
				...additionalInfoInput2,
				required: false
			})
			setEntrepreneurUnknownInput2({
				...entrepreneurUnknownInput2,
				value: 'false'
			})
			setEntrepreneurFieldsRequired(false)

			setDisabledMunicipalConduits([])
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isWaterAndSewerConnectionRequiredInput.name,
						Q42AdditionalInfoInput.name,
						additionalInfoInput2.name,
						entrepreneurUnknownInput2.name,
						entrepreneurNameInput.name,
						entrepreneurRBQNumberInput.name,
						entrepreneurRepresentativeNameInput.name,
						entrepreneurRepresentativePhoneNumberInput.name,
						entrepreneurRepresentativeEmailInput.name,
						entrepreneurRepresentativeAddressInput.name,
						entrepreneurRepresentativeCityInput.name,
						entrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isWaterAndSewerConnectionRequiredInput)

	//------------------------------------------------------------

	const [disabledMunicipalConduits, setDisabledMunicipalConduits] = useState<
		string[]
	>([])

	const onSetIsPropertyConnectedToMunicipalConduitsInput = (value: string) => {
		let newValue
		if (isPropertyConnectedToMunicipalConduitsInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				isPropertyConnectedToMunicipalConduitsInput.value
			)
		} else {
			newValue =
				isPropertyConnectedToMunicipalConduitsInput.value == ''
					? value
					: isPropertyConnectedToMunicipalConduitsInput.value + `, ${value}`
		}

		setIsPropertyConnectedToMunicipalConduitsInput({
			...isPropertyConnectedToMunicipalConduitsInput,
			value: newValue
		})

		// Disable the value of the radio button that is not selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value !==
			value
		) {
			setDisabledMunicipalConduits([
				radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value
			])
		}
		// Disable the value of the radio button that is selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value ==
			value
		) {
			setDisabledMunicipalConduits(
				radioListIsPropertyConnectedToMunicipalConduits
					.slice(0, 4)
					.map((item) => item.value)
			)
		}
		// Enable the value of the radio button if the value is empty
		if (newValue == '') {
			setDisabledMunicipalConduits([])
		}

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				isPropertyConnectedToMunicipalConduitsInput.name
			)
		)
	}

	useUpdateInputs(isPropertyConnectedToMunicipalConduitsInput)

	//-------------------------------------------------------------

	const onSetQ42AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ42AdditionalInfoInput,
			Q42AdditionalInfoInput,
			value,
			Q42AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q42AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetPlannedWorkInput = (value: string) => {
		let newValue
		if (plannedWorkInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(value, plannedWorkInput.value)
		} else {
			newValue =
				plannedWorkInput.value == ''
					? value
					: plannedWorkInput.value + `, ${value}`
		}

		setPlannedWorkInput({
			...plannedWorkInput,
			value: newValue
		})

		if (newValue.includes(listPlannedWork.slice(-1)[0].value)) {
			setAdditionalInfoInput2({
				...additionalInfoInput2,
				required: true
			})
		} else {
			setAdditionalInfoInput2({
				...additionalInfoInput2,
				required: false
			})
		}

		if (isEntrepreneurFieldsRequired(newValue)) {
			if (entrepreneurUnknownInput2.value?.toString() == 'true') {
				setEntrepreneurFieldsRequired(false)
			} else {
				setEntrepreneurFieldsRequired(true)
			}
		} else {
			setEntrepreneurFieldsRequired(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						plannedWorkInput.name,
						additionalInfoInput2.name,
						entrepreneurUnknownInput2.name,
						entrepreneurNameInput.name,
						entrepreneurRBQNumberInput.name,
						entrepreneurRepresentativeNameInput.name,
						entrepreneurRepresentativePhoneNumberInput.name,
						entrepreneurRepresentativeEmailInput.name,
						entrepreneurRepresentativeAddressInput.name,
						entrepreneurRepresentativeCityInput.name,
						entrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(plannedWorkInput)
	// --------------------------------------------------------------------------

	const onSetAdditionalInfoInput2 = (value: string) => {
		handleInputChange(
			setAdditionalInfoInput2,
			additionalInfoInput2,
			value,
			additionalInfoInput2.name
		)
	}

	useUpdateInputs(additionalInfoInput2)

	// --------------------------------------------------------------------------

	const setEntrepreneurFieldsRequired = (value) => {
		setEntrepreneurNameInput({
			...entrepreneurNameInput,
			required: value
		})
		setEntrepreneurRBQNumberInput({
			...entrepreneurRBQNumberInput,
			required: value
		})
		setEntrepreneurRepresentativeNameInput({
			...entrepreneurRepresentativeNameInput,
			required: value
		})
		setEntrepreneurRepresentativePhoneNumberInput({
			...entrepreneurRepresentativePhoneNumberInput,
			required: value
		})
		setEntrepreneurRepresentativeEmailInput({
			...entrepreneurRepresentativeEmailInput,
			required: value
		})
		setEntrepreneurRepresentativeAddressInput({
			...entrepreneurRepresentativeAddressInput,
			required: value
		})
		setEntrepreneurRepresentativeCityInput({
			...entrepreneurRepresentativeCityInput,
			required: value
		})
		setEntrepreneurRepresentativePostalCodeInput({
			...entrepreneurRepresentativePostalCodeInput,
			required: value
		})
	}

	const onSetEntrepreneurUnknownInput2 = (value: string) => {
		setEntrepreneurUnknownInput2({
			...entrepreneurUnknownInput2,
			value
		})

		if (
			isEntrepreneurFieldsRequired(plannedWorkInput.value) &&
			value.toString() == 'true'
		) {
			setEntrepreneurFieldsRequired(false)
		} else {
			setEntrepreneurFieldsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						entrepreneurUnknownInput2.name,
						entrepreneurNameInput.name,
						entrepreneurRBQNumberInput.name,
						entrepreneurRepresentativeNameInput.name,
						entrepreneurRepresentativePhoneNumberInput.name,
						entrepreneurRepresentativeEmailInput.name,
						entrepreneurRepresentativeAddressInput.name,
						entrepreneurRepresentativeCityInput.name,
						entrepreneurRepresentativePostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(entrepreneurUnknownInput2)
	// --------------------------------------------------------------------------

	const onSetEntrepreneurNameInput = (value: string) => {
		handleInputChange(
			setEntrepreneurNameInput,
			entrepreneurNameInput,
			value,
			entrepreneurNameInput.name
		)
	}

	useUpdateInputs(entrepreneurNameInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRBQNumberInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRBQNumberInput,
			entrepreneurRBQNumberInput,
			value,
			entrepreneurRBQNumberInput.name
		)
	}

	useUpdateInputs(entrepreneurRBQNumberInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRepresentativeNameInput,
			entrepreneurRepresentativeNameInput,
			value,
			entrepreneurRepresentativeNameInput.name
		)
	}

	useUpdateInputs(entrepreneurRepresentativeNameInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRepresentativePhoneNumberInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRepresentativePhoneNumberInput,
			entrepreneurRepresentativePhoneNumberInput,
			value,
			entrepreneurRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(entrepreneurRepresentativePhoneNumberInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRepresentativeEmailInput,
			entrepreneurRepresentativeEmailInput,
			value,
			entrepreneurRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(entrepreneurRepresentativeEmailInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRepresentativeAddressInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRepresentativeAddressInput,
			entrepreneurRepresentativeAddressInput,
			value,
			entrepreneurRepresentativeAddressInput.name
		)
	}

	useUpdateInputs(entrepreneurRepresentativeAddressInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRepresentativeCityInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRepresentativeCityInput,
			entrepreneurRepresentativeCityInput,
			value,
			entrepreneurRepresentativeCityInput.name
		)
	}

	useUpdateInputs(entrepreneurRepresentativeCityInput)

	// --------------------------------------------------------------------------

	const onSetEntrepreneurRepresentativePostalCodeInput = (value: string) => {
		handleInputChange(
			setEntrepreneurRepresentativePostalCodeInput,
			entrepreneurRepresentativePostalCodeInput,
			value?.toUpperCase(),
			entrepreneurRepresentativePostalCodeInput.name
		)
	}

	useUpdateInputs(entrepreneurRepresentativePostalCodeInput)

	// --------------------------------------------------------------------------
	// --------------------------------------------------------------------------

	const onSetWorkTypeBuildingInput = (value: string) => {
		let newValue
		if (workTypeBuildingInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				workTypeBuildingInput.value
			)
		} else {
			newValue =
				workTypeBuildingInput.value == ''
					? value
					: workTypeBuildingInput.value + `, ${value}`
		}

		setWorkTypeBuildingInput({
			...workTypeBuildingInput,
			value: newValue
		})

		if (value == REQUEST_CONFIGS_KEYS.workTypeBuilding.seventhOption) {
			setQ11_1WorkTypeBuildingPrecisionInput({
				...Q11_1WorkTypeBuildingPrecisionInput,
				required: true
			})
		} else {
			setQ11_1WorkTypeBuildingPrecisionInput({
				...Q11_1WorkTypeBuildingPrecisionInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						workTypeBuildingInput.name,
						Q11_1WorkTypeBuildingPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(workTypeBuildingInput)

	//------------------------------------------------------------
	const onSetQ11_1WorkTypeBuildingPrecisionInput = (value: string) => {
		if (value != Q11_1WorkTypeBuildingPrecisionInput.value) {
			setQ11_1WorkTypeBuildingPrecisionInput({
				...Q11_1WorkTypeBuildingPrecisionInput,
				value
			})
		}

		handleInputChange(
			setQ11_1WorkTypeBuildingPrecisionInput,
			Q11_1WorkTypeBuildingPrecisionInput,
			value,
			Q11_1WorkTypeBuildingPrecisionInput.name
		)
	}
	useUpdateInputs(Q11_1WorkTypeBuildingPrecisionInput)

	//------------------------------------------------------------

	const onSetExistingMainBuildingHasHousingInput = (value: string) => {
		handleInputChange(
			setExistingMainBuildingHasHousingInput,
			mainBuildingHasHousingInput,
			value,
			mainBuildingHasHousingInput.name
		)
	}

	useUpdateInputs(mainBuildingHasHousingInput)

	const onSetMainUseOfBuildingInput = (value: string) => {
		setMainUseOfBuildingInput({
			...mainUseOfBuildingInput,
			value
		})

		if (value == REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption) {
			setExistingMainBuildingHasHousingInput({
				...mainBuildingHasHousingInput,
				required: true
			})
		} else {
			setExistingMainBuildingHasHousingInput({
				...mainBuildingHasHousingInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						mainUseOfBuildingInput.name,
						mainBuildingHasHousingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(mainUseOfBuildingInput)

	// --------------------------------------------------------------------------

	//------------------------------------------------------------

	const onSetIsTheBuildingPartOfAPropertyInput = (value: string) => {
		setIsTheBuildingPartOfAPropertyInput({
			...isTheBuildingPartOfAPropertyInput,
			value
		})

		onFixError(
			errors.filter(
				(error) => ![isTheBuildingPartOfAPropertyInput.name].includes(error)
			)
		)
	}

	useUpdateInputs(isTheBuildingPartOfAPropertyInput)

	// --------------------------------------------------------------------------

	//////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////

	// useEffect(() => {
	// 	onSetInputs(UpdateRequestInputsArray(inputs!, dummyOptionalInput))
	// }, [dummyOptionalInput])

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const updateQuestionFiles = (setQuestion, question, files) => {
		setQuestion({
			...question,
			files
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, question.name))
	}

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFirstQuestion, firstQuestion, files)
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSecondQuestion, secondQuestion, files)
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirdQuestion, thirdQuestion, files)
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourthQuestion, fourthQuestion, files)
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifthQuestion, fifthQuestion, files)
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixthQuestion, sixthQuestion, files)
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventhQuestion, seventhQuestion, files)
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEighthQuestion, eighthQuestion, files)
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setNinthQuestion, ninthQuestion, files)
	}

	const onSelectFileTenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTenthQuestion, tenthQuestion, files)
	}

	const onSelectFileEleventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEleventhQuestion, eleventhQuestion, files)
	}

	const onSelectFileTwelfthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTwelfthQuestion, twelfthQuestion, files)
	}

	const onSelectFileThirteenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirteenthQuestion, thirteenthQuestion, files)
	}

	const onSelectFileFourteenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourteenthQuestion, fourteenthQuestion, files)
	}

	const onSelectFileFifteenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifteenthQuestion, fifteenthQuestion, files)
	}

	const onSelectFileSixteenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixteenthQuestion, sixteenthQuestion, files)
	}

	const onSelectFileSeventeenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventeenthQuestion, seventeenthQuestion, files)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthQuestion))
	}, [tenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eleventhQuestion))
	}, [eleventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, twelfthQuestion))
	}, [twelfthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifteenthQuestion))
	}, [fifteenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixteenthQuestion))
	}, [sixteenthQuestion])

	useEffect(() => {
		onSetInputFiles(
			UpdateRequestInputFilesArray(inputFiles, seventeenthQuestion)
		)
	}, [seventeenthQuestion])

	useEffect(() => {
		const currentNumberHome = currentNumberHomeInput?.value
			? parseInt(currentNumberHomeInput.value) || 0
			: 0

		const numberDwellingAdded = numberDwellingAddedInput?.value
			? parseInt(numberDwellingAddedInput.value) || 0
			: 0

		const range = currentNumberHome + numberDwellingAdded

		if (range <= 1) {
			setDocumentCategory('[0-1]')
		} else if (range >= 2 && range <= 5) {
			setDocumentCategory('[2-6]')
		} else if (range >= 6) {
			setDocumentCategory('[6-+]')
		} else {
			setDocumentCategory('')
		}
	}, [currentNumberHomeInput?.value, numberDwellingAddedInput?.value])

	// Helper function to set question files
	const setQuestionFiles = (inputName: string, question, setQuestion) => {
		if (!!answers[inputName] && question.files.size == 0) {
			getFilesFromStore(inputName, answers).then((result: Set<File>) => {
				setQuestion({
					...question,
					files: result
				})
			})
		}
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		setQuestionFiles('firstInput', firstQuestion, setFirstQuestion)

		// secondInput input
		setQuestionFiles('secondInput', secondQuestion, setSecondQuestion)

		// thirdInput input
		setQuestionFiles('thirdInput', thirdQuestion, setThirdQuestion)

		// fouthInput input
		setQuestionFiles('fourthInput', fourthQuestion, setFourthQuestion)

		// fifthInput input
		setQuestionFiles('fifthInput', fifthQuestion, setFifthQuestion)

		// sixthQuestion input
		setQuestionFiles('sixthInput', sixthQuestion, setSixthQuestion)

		// seventhQuestion input
		setQuestionFiles('seventhInput', seventhQuestion, setSeventhQuestion)

		// eighthQuestion input
		setQuestionFiles('eighthInput', eighthQuestion, setEighthQuestion)

		// ninthQuestion input
		setQuestionFiles('ninthInput', ninthQuestion, setNinthQuestion)

		// tenthQuestion input
		setQuestionFiles('tenthInput', tenthQuestion, setTenthQuestion)

		// eleventhQuestion input
		setQuestionFiles('eleventhInput', eleventhQuestion, setEleventhQuestion)

		// twelfthQuestion input
		setQuestionFiles('twelfthInput', twelfthQuestion, setTwelfthQuestion)

		// thirteenthQuestion input
		setQuestionFiles(
			'thirteenthInput',
			thirteenthQuestion,
			setThirteenthQuestion
		)

		// fourteenthQuestion input
		setQuestionFiles(
			'fourteenthInput',
			fourteenthQuestion,
			setFourteenthQuestion
		)

		// fifteenQuestion input
		setQuestionFiles('fifteenthInput', fifteenthQuestion, setFifteenthQuestion)

		// fourteenthQuestion input
		setQuestionFiles('sixteenthInput', sixteenthQuestion, setSixteenthQuestion)

		// fourteenthQuestion input
		setQuestionFiles(
			'seventeenthInput',
			seventeenthQuestion,
			setSeventeenthQuestion
		)
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		// setSteps &&
		// 	setSteps({
		// 		...StepsUrbanism
		// 	})

		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			details_5: true,
			details_6: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		setStepsList &&
			setStepsList([
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step1),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step2),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step3),
					subSteps: [
						pageAssets?.request_form_type_de_demandes,
						pageAssets?.request_form_request_location,
						pageAssets?.request_form_type_of_use,
						pageAssets?.request_form_building_informations,
						pageAssets?.request_form_information_on_the_work,
						pageAssets?.request_form_branching,
						pageAssets?.request_form_required_documents
					]
				},
				// {
				// 	title: formatStrapiText(
				// 		pageAssets?.page_requestInfo_step_complementary_requests
				// 	),
				// 	subSteps: [pageAssets?.page_requestInfo_step_complementary_requests]
				// },
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step4),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step5),
					subSteps: []
				}
			])
	}, [])

	const backToForm = (
		step: number,
		subStep: string,
		currentSubStep: number
	) => {
		if (setCurrentStep && onSetSubSteps && subSteps && onSetCurrentSubStep) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
			onSetCurrentSubStep(currentSubStep)
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const handleMeasurementInputChange = (
		value,
		measurementInputOnChange,
		maxCharacters
	) => {
		const cleanedValue = value.replace(/[.,]/g, '')

		if (cleanedValue.length <= maxCharacters) {
			measurementInputOnChange(value)
		}
	}

	const renderMeasurementInput = (
		measurementInput,
		measurementInputOnChange,
		unitInput,
		unitInputOnchange,
		listMeasurementUnit,
		maxCharacters,
		hasThousandsSeparator: boolean = false
	) => {
		return (
			<div className={classes.subContent}>
				{hasThousandsSeparator ? (
					<MaskedThousandsSeparatorInput
						id={measurementInput.name}
						label={measurementInput.label}
						value={measurementInput.value}
						onChange={(value) =>
							handleMeasurementInputChange(
								value,
								measurementInputOnChange,
								maxCharacters
							)
						}
						hasError={errors.includes(measurementInput.name)}
						required={measurementInput.required}
						ref={measurementInput.ref as RefObject<HTMLInputElement>}
						errors={errors}
						setError={onFixError}
						maxCharacters={maxCharacters}
					/>
				) : (
					<TextInput
						id={measurementInput.name}
						type="number"
						label={measurementInput.label}
						value={measurementInput.value ?? ''}
						onChange={(value) =>
							handleMeasurementInputChange(
								value,
								measurementInputOnChange,
								maxCharacters
							)
						}
						hasError={errors?.includes(measurementInput.name)}
						customErrorText={
							measurementInput.value !== ''
								? 'Veuillez entrer un nombre valide.'
								: undefined
						}
						onKeyPress={pageUtils.onKeyPressNumberInput}
						min="0"
						required={measurementInput.required}
						maxCharacters={maxCharacters}
					/>
				)}

				<RadioInput
					name={unitInput.name}
					label={''}
					value={unitInput.value ?? ''}
					list={listMeasurementUnit}
					direction={Direction.horizontal}
					hasError={errors!.includes(unitInput.name)}
					onChange={unitInputOnchange}
					hasBackgroundWrapper={true}
				/>
			</div>
		)
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<div>
					<strong>{question}</strong>
					<br />
					<span className={pageUtils.classes.answerURB}>{answer}</span>
				</div>
			)
		}
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	// a function to render RequestFiles on the DOCUMENT or Summary step
	const renderRequestFiles = (
		type: string,
		category: string | undefined,
		question: RequestInputFiles,
		onSelectFileFunction,
		status: string,
		isSummary?: boolean
	) => {
		if (isSummary == true) {
			return (
				isDocumentToShow(type, category, question.name, status) &&
				question.files.size > 0 && (
					<RequestFiles
						subtitle={question.label}
						name={question.name}
						required={question.required}
						files={question.files}
						hasError={errors.includes(question.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFunction}
						maxFiles={question?.numberMaxFiles}
						isSummary
					/>
				)
			)
		}

		return (
			isDocumentToShow(type, category, question.name, status, true) && (
				<RequestFiles
					subtitle={question.label}
					name={question.name}
					required={question.required}
					files={question.files}
					hasError={errors.includes(question.name)}
					removeSectionMarginTop
					onSetHasError={onSetErrorFromFileComponent}
					onSetFiles={onSelectFileFunction}
					subSteps={subSteps}
					maxFiles={question?.numberMaxFiles}
					description={question.description}
				/>
			)
		)
	}

	const renderAllRequestFiles = (status: string, isSummary: boolean) => {
		return (
			<>
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					firstQuestion,
					onSelectFileTwelfthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					secondQuestion,
					onSelectFileSecondQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					thirdQuestion,
					onSelectFileThirdQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					fourthQuestion,
					onSelectFileFourthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					fifthQuestion,
					onSelectFileFifthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					sixthQuestion,
					onSelectFileSixthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					seventhQuestion,
					onSelectFileSeventhQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					eighthQuestion,
					onSelectFileEighthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					ninthQuestion,
					onSelectFileNinthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					tenthQuestion,
					onSelectFileTenthQuestion,
					status,
					isSummary
				)}

				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					twelfthQuestion,
					onSelectFileTwelfthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					thirteenthQuestion,
					onSelectFileThirteenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					fourteenthQuestion,
					onSelectFileFourteenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					fifteenthQuestion,
					onSelectFileFifteenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					sixteenthQuestion,
					onSelectFileSixteenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					seventeenthQuestion,
					onSelectFileSeventeenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'DEFAULT',
					documeentCategory,
					eleventhQuestion,
					onSelectFileEleventhQuestion,
					status,
					isSummary
				)}
			</>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={joinClasses([
					pageUtils.classes.noMargin,
					pageUtils.classes.collapse,
					pageUtils.classes.summarySubTitles,
					toPrint ? pageUtils.classes.toPrintTitle : ''
				])}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<Collapse
							id={1}
							buttonText={pageAssets?.request_form_type_de_demandes}
							isOpen={toPrint}
							key={`request_form_type_de_demandes`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
												1
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_de_demandes}
									</h4>
								)}
							</div>

							{/* Question 1 */}

							<div>
								<strong>{requestAsInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListRequestAs, answers?.requestAs)}
								</span>
							</div>

							{(answers?.requestAs?.toString() ==
								REQUEST_CONFIGS_KEYS.requestAs.entreprise ||
								answers?.requestAs?.toString() ==
									REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager) && (
								<>
									<div>
										<strong>{companyNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.companyName}
										</span>
									</div>
									<div>
										<strong>{representativeNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.representativeName}
										</span>
									</div>
								</>
							)}

							{/* Question 2: Is Subject to PIIA Approval */}
							<div>
								<strong>{isSubjectToPiiaApprovalInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isSubjectToPiiaApproval
									)}
								</span>
							</div>

							{/* Question 4: Has Been Subject to Approval */}
							{answers?.isSubjectToPiiaApproval?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>{hasBeenSubjectToApprovalInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{getLabelFromList(
												radioListYesNo,
												answers?.hasBeenSubjectToApproval
											)}
										</span>
									</div>

									{answers?.hasBeenSubjectToApproval?.toString() ==
										radioListYesNo[0].value && (
										<>
											{/* Question 4.1: IA Number */}
											<div>
												<strong>{iaNumberInput.label}</strong>
												<br />
												<span className={pageUtils.classes.answerURB}>
													{answers?.iaNumber}
												</span>
											</div>

											{/* Question 4.2: EC Resolution Number */}
											{answers?.ecResolutionNumber && (
												<div>
													<strong>{ecResolutionNumberInput.label}</strong>
													<br />
													<span className={pageUtils.classes.answerURB}>
														{answers?.ecResolutionNumber}
													</span>
												</div>
											)}
										</>
									)}
								</>
							)}

							{/* Question 5: Is Real Estate Project */}
							<div>
								<strong>{isRealEstateProjectInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isRealEstateProject
									)}
								</span>
							</div>

							{/* Question 6: Project Number */}
							{answers?.isRealEstateProject?.toString() ==
								radioListYesNo[0].value && (
								<div>
									<strong>{projectNumberInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{answers?.projectNumber}
									</span>
								</div>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<Collapse
							id={2}
							buttonText={pageAssets?.request_form_request_location}
							isOpen={toPrint}
							key={`request_form_request_location`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
												2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_request_location}
									</h4>
								)}
							</div>

							<div>
								<strong>{isSameLocationInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.buildingAddressSameHasApplicant
									)}
								</span>
							</div>

							{/* Question 8 */}
							{answers?.buildingAddressSameHasApplicant?.toString() ==
								radioListYesNo[1].value && (
								<>
									<div>
										<strong>{pageAssets?.request_form_location}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.addressField ? (
												<>
													{answers?.addressField}
													<br />
												</>
											) : (
												''
											)}

											{answers?.addressLotNumber
												? pageAssets?.label_cadastral_number +
												  ': ' +
												  answers?.addressLotNumber
												: ''}
										</span>
									</div>
									{answers?.addressVoiePublic && (
										<div>
											<strong>
												{
													pageAssets?.request_form_addressVoiePublicSection_public_road
												}
											</strong>
											<br />
											<span>
												{answers?.addressCivicNumber
													? answers?.addressCivicNumber + ', '
													: ''}
												{answers?.addressVoiePublic}
											</span>
										</div>
									)}
									{answers?.addressVoiePublic2 && (
										<div>
											<span>
												{answers?.addressCivicNumber2
													? answers?.addressCivicNumber2 + ', '
													: ''}
												{answers?.addressVoiePublic2}
											</span>
										</div>
									)}

									{answers?.addressVoiePublic3 && (
										<div>
											<span>
												{answers?.addressCivicNumber3
													? answers?.addressCivicNumber3 + ', '
													: ''}
												{answers?.addressVoiePublic3}
											</span>
										</div>
									)}

									{answers?.addressAdditionalInfos && (
										<div>
											<span>{answers?.addressAdditionalInfos}</span>
										</div>
									)}
									<br />
								</>
							)}

							<div>
								<strong>{isLocationOwnedInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListYesNo, answers?.isLocationOwned)}
								</span>
							</div>

							{/* ****** Owner inputs ****** */}
							{answers?.isLocationOwned?.toString() ==
								radioListYesNo[1].value && (
								<>
									{questionAnswer(
										ownerFirstNameInput.label,
										answers?.ownerFirstName
									)}
									{questionAnswer(
										ownerLastNameInput.label,
										answers?.ownerLastName
									)}
									{questionAnswer(ownerEmailInput.label, answers?.ownerEmail)}
									{questionAnswer(
										ownerPhoneNumberInput.label,
										answers?.ownerPhoneNumber
									)}

									<RequestFiles
										subtitle={firstQuestion.label}
										name={firstQuestion.name}
										required={firstQuestion.required}
										files={firstQuestion.files}
										hasError={errors.includes(firstQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileFirstQuestion}
										maxFiles={firstQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
									<br />
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<Collapse
							id={3}
							buttonText={pageAssets?.request_form_type_of_use}
							isOpen={toPrint}
							key={`request_form_type_of_use`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
												3
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_of_use}
									</h4>
								)}
							</div>

							<div>
								<>
									{questionAnswer(
										mainUseOfBuildingInput.label,
										getLabelFromList(
											radioListMainUseEnlargementBuilding,
											answers?.mainUseOfExistingBuilding
										)
									)}
									{answers?.mainUseOfExistingBuilding?.toString() ==
										REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding
											.secondOption &&
										questionAnswer(
											mainBuildingHasHousingInput.label,
											getLabelFromList(
												radioListYesNo,
												answers?.mainBuildingHasHousing
											)
										)}
									{questionAnswer(
										structureTypeInput.label,
										getLabelFromList(
											radioListStructureType,
											answers?.structureType
										)
									)}
									{questionAnswer(
										isTheBuildingPartOfAPropertyInput.label,
										getLabelFromList(
											radioListYesNo,
											answers?.isTheNewBuildingPartOfAProperty
										)
									)}
								</>
							</div>
						</Collapse>
					</>
				)}
				{subSteps?.details_4 && (
					<>
						<Collapse
							id={4}
							buttonText={pageAssets?.request_form_building_informations}
							isOpen={toPrint}
							key={`request_form_building_informations`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
												4
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_building_informations}
									</h4>
								)}
							</div>

							<>
								<>
									{getLabelsFromString(
										radioListWorkTypeEnlargementBuilding,
										answers?.workTypeBuilding
									).map((answerLabel, index) => {
										return (
											<p
												className={pageUtils.classes.answerURB}
												key={`${index}-workTypeBuilding`}
											>
												{answerLabel}
											</p>
										)
									})}
									{answers?.workTypeBuilding
										?.toString()
										.includes(
											REQUEST_CONFIGS_KEYS.workTypeBuilding.seventhOption
										) &&
										questionAnswer(
											Q11_1WorkTypeBuildingPrecisionInput.label,
											answers?.workTypeBuildingPrecision
										)}
								</>

								{/* Question 12 */}
								<>
									{questionAnswer(
										workBuildingAreaInput.label,
										`${formatNumberWithSpaces(
											answers?.workBuildingArea?.toString()
										)} ${getLabelFromList(
											radioListMeasurement2Unit,
											answers?.workBuildingAreaUnit
										)}`
									)}
								</>
								<>
									{questionAnswer(
										numberDwellingAddedInput.label,
										answers?.numberDwellingAdded?.toString()
									)}
								</>
								{/* Question 18 */}
								<>
									{questionAnswer(
										currentNumberHomeInput.label,
										answers?.currentNumberHome?.toString()
									)}
								</>
								{/* Question 19 */}
								{questionAnswer(
									plumbingWorkInput.label,
									getLabelFromList(radioListYesNo, answers?.plumbingWork)
								)}
								{questionAnswer(
									stabLevelInput.label,
									answers?.stabLevelToStreet?.toString()
								)}
							</>
						</Collapse>
					</>
				)}

				{subSteps?.details_5 && (
					<>
						<Collapse
							id={4}
							buttonText={pageAssets?.request_form_information_on_the_work}
							isOpen={toPrint}
							key={`request_form_information_on_the_work`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
												5
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}

								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_information_on_the_work}
									</h4>
								)}
							</div>

							{/* Question 24 */}
							<div>
								<strong>{isWorkDueToDisasterInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isWorkDueToDisaster
									)}
								</span>
							</div>

							{answers?.isWorkDueToDisaster?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>{workDueToDisasterPrecisionInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.workDueToDisasterPrecision}
										</span>
									</div>
								</>
							)}

							{/* Question 25 */}
							<div>
								<strong>{approximateCostOfWorkInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{formatNumberWithSpaces(
										answers?.approximateCostOfWork?.toString()
									)}{' '}
									$
								</span>
							</div>

							{/* Question 27 */}
							{questionAnswer(
								isTreeCuttingRequiredInput.label,
								getLabelFromList(radioListYesNo, answers?.isTreeCuttingRequired)
							)}

							{/* Question 28 */}
							{questionAnswer(
								additionalInfoInput.label,
								answers?.additionalInfoQ28
							)}

							{/* Dates des travaux */}
							{(answers?.workStartDate || answers?.workEndDate) && (
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
									}
								</h5>
							)}

							{answers?.workStartDate && (
								<div>
									<strong>{startDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workStartDate!)}
									</span>
								</div>
							)}

							{answers?.workEndDate && (
								<div>
									<strong>{endDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workEndDate!)}
									</span>
								</div>
							)}

							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
								}
							</h5>

							{/* Question 31 */}
							{questionAnswer(
								whoWillBeInChargeOfWorkInput.label,
								getLabelFromList(
									radioListWhoWillBeInChargeOfWork,
									answers?.whoWillBeInChargeOfWork
								)
							)}

							{[
								radioListWhoWillBeInChargeOfWork[1].value,
								radioListWhoWillBeInChargeOfWork[2].value
							].includes(answers?.whoWillBeInChargeOfWork?.toString()!) &&
								questionAnswer(
									entrepreneurUnknownInput.label,
									getLabelFromList(
										radioListYesNo,
										answers?.entrepreneurUnknown1?.toString() || String(false)
									)
								)}

							{questionAnswer(
								companyNameExecutingWorkInput.label,
								answers?.companyNameExecutingWork
							)}

							{questionAnswer(
								companyNEQNumberInput.label,
								answers?.companyNEQNumber
							)}

							{questionAnswer(
								companyRBQNumberInput.label,
								answers?.companyRBQNumber
							)}

							{(answers?.companyRepresentativeName ||
								answers?.companyRepresentativePhoneNumber ||
								answers?.companyRepresentativeEmail) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
									}
								</h6>
							)}

							{questionAnswer(
								companyRepresentativeNameInput.label,
								answers?.companyRepresentativeName
							)}

							{questionAnswer(
								companyRepresentativePhoneNumberInput.label,
								answers?.companyRepresentativePhoneNumber
							)}

							{questionAnswer(
								companyRepresentativeEmailInput.label,
								answers?.companyRepresentativeEmail
							)}

							{(answers?.companyAddress ||
								answers?.companyCity ||
								answers?.companyPostalCode) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
									}
								</h6>
							)}

							{questionAnswer(
								companyAddressInput.label,
								answers?.companyAddress
							)}

							{questionAnswer(companyCityInput.label, answers?.companyCity)}

							{questionAnswer(
								companyPostalCodeInput.label,
								answers?.companyPostalCode
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_6 && (
					<>
						<Collapse
							id={5}
							buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
							isOpen={toPrint}
							key={`request_form_branching_aqueduc_egouts`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
												6
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_branching_aqueduc_egouts}
									</h4>
								)}
							</div>

							{questionAnswer(
								isWaterAndSewerConnectionRequiredInput.label,
								getLabelFromList(
									radioListYesNo,
									answers?.isWaterAndSewerConnectionRequired?.toString() ||
										String(false)
								)
							)}

							{answers?.isPropertyConnectedToMunicipalConduits && (
								<div>
									<strong>
										{isPropertyConnectedToMunicipalConduitsInput.label}
									</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										<>
											{getLabelsFromString(
												radioListIsPropertyConnectedToMunicipalConduits,
												answers?.isPropertyConnectedToMunicipalConduits
											).map((answerLabel, index) => {
												return (
													<p
														className={pageUtils.classes.answerURB}
														key={`${index}-isPropertyConnectedToMunicipalConduits`}
													>
														{answerLabel}
													</p>
												)
											})}
										</>
									</span>
								</div>
							)}

							{questionAnswer(
								Q42AdditionalInfoInput.label,
								answers?.additionalInfoQ42
							)}

							{answers?.plannedWork && (
								<div>
									<strong>{plannedWorkInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										<>
											{getLabelsFromString(
												listPlannedWork,
												answers?.plannedWork
											).map((answerLabel, index) => {
												return (
													<p
														className={pageUtils.classes.answerURB}
														key={`${index}-plannedWork`}
													>
														{answerLabel}
													</p>
												)
											})}
										</>
									</span>
								</div>
							)}

							{questionAnswer(
								additionalInfoInput2.label,
								answers?.additionalInfoQ44
							)}

							{isEntrepreneurFieldsRequired(answers?.plannedWork) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
										}
									</h5>

									{questionAnswer(
										entrepreneurUnknownInput2.label,
										getLabelFromList(
											radioListYesNo,
											answers?.entrepreneurUnknown?.toString() || String(false)
										)
									)}

									{questionAnswer(
										entrepreneurNameInput.label,
										answers?.entrepreneurName
									)}

									{questionAnswer(
										entrepreneurRBQNumberInput.label,
										answers?.entrepreneurRBQNumber
									)}

									{(answers?.entrepreneurRepresentativeName ||
										answers?.entrepreneurRepresentativePhoneNumber ||
										answers?.entrepreneurRepresentativeEmail) && (
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>
									)}

									{questionAnswer(
										entrepreneurRepresentativeNameInput.label,
										answers?.entrepreneurRepresentativeName
									)}

									{questionAnswer(
										entrepreneurRepresentativePhoneNumberInput.label,
										answers?.entrepreneurRepresentativePhoneNumber
									)}

									{questionAnswer(
										entrepreneurRepresentativeEmailInput.label,
										answers?.entrepreneurRepresentativeEmail
									)}

									{(answers?.entrepreneurRepresentativeAddress ||
										answers?.entrepreneurRepresentativeCity ||
										answers?.entrepreneurRepresentativePostalCode) && (
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>
									)}

									{questionAnswer(
										entrepreneurRepresentativeAddressInput.label,
										answers?.entrepreneurRepresentativeAddress
									)}

									{questionAnswer(
										entrepreneurRepresentativeCityInput.label,
										answers?.entrepreneurRepresentativeCity
									)}

									{questionAnswer(
										entrepreneurRepresentativePostalCodeInput.label,
										answers?.entrepreneurRepresentativePostalCode
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<Collapse
							id={6}
							buttonText={pageAssets?.request_form_required_documents}
							isOpen={toPrint}
							key={`request_form_required_documents`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
												7
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_required_documents}
									</h4>
								)}
							</div>

							<>
								{/* true stands for isSummary */}
								{renderAllRequestFiles('required', true)}
								{renderAllRequestFiles('optional', true)}
							</>
						</Collapse>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_de_demandes}
						</h4>

						<RadioInput
							name={requestAsInput.name}
							label={requestAsInput.label}
							value={requestAsInput.value}
							list={radioListRequestAs}
							direction={Direction.vertical}
							hasError={errors!.includes(requestAsInput.name)}
							onChange={onSetRequestAsInput}
							ref={requestAsInput.ref as RefObject<HTMLInputElement>}
							required={requestAsInput.required}
							hasBackgroundWrapper={true}
						/>

						{(requestAsInput.value ==
							REQUEST_CONFIGS_KEYS.requestAs.entreprise ||
							requestAsInput.value ==
								REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager) && (
							<>
								{/* Question 2.1 */}
								<TextInput
									id={companyNameInput.name}
									label={companyNameInput.label}
									value={companyNameInput.value}
									onChange={onSetCompanyNameInput}
									hasError={errors?.includes(companyNameInput.name)}
									required={companyNameInput.required}
									ref={companyNameInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>

								{/* Question 2.2 */}
								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						{/* Architectural implementation and integration plan (PIIA) */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_piia_title
							}
						</h5>

						{/* Question 3 */}
						<RadioInput
							name={isSubjectToPiiaApprovalInput.name}
							label={isSubjectToPiiaApprovalInput.label}
							value={isSubjectToPiiaApprovalInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSubjectToPiiaApprovalInput.name)}
							onChange={onSetIsSubjectToPiiaApprovalInput}
							ref={
								isSubjectToPiiaApprovalInput.ref as RefObject<HTMLInputElement>
							}
							required={isSubjectToPiiaApprovalInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
							description={
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description
							}
						/>

						{isSubjectToPiiaApprovalInput.value == radioListYesNo[0].value && (
							<>
								{/* Question 4 */}
								<RadioInput
									name={hasBeenSubjectToApprovalInput.name}
									label={hasBeenSubjectToApprovalInput.label}
									value={hasBeenSubjectToApprovalInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(
										hasBeenSubjectToApprovalInput.name
									)}
									onChange={onSetHasBeenSubjectToApprovalInput}
									ref={
										hasBeenSubjectToApprovalInput.ref as RefObject<HTMLInputElement>
									}
									required={hasBeenSubjectToApprovalInput.required}
									hasBackgroundWrapper={true}
									classWrapper={classes.reducedMarginBottom}
								/>
								{hasBeenSubjectToApprovalInput.value ==
									radioListYesNo[0].value && (
									<>
										<MaskedTextInput
											id={iaNumberInput.name}
											label={iaNumberInput.label}
											value={iaNumberInput.value}
											onChange={onSetIaNumberInput}
											hasError={errors.includes(iaNumberInput.name)}
											required={iaNumberInput.required}
											ref={iaNumberInput.ref as RefObject<HTMLInputElement>}
											max={12}
											min={12}
											mask="IA-0000-0000"
											placeholder="IA-AAAA-XXXX"
											errors={errors}
											setError={onFixError}
											schema={/^IA-\d{4}-\d{4}$/}
										/>

										<MaskedTextInput
											id={ecResolutionNumberInput.name}
											label={ecResolutionNumberInput.label}
											value={ecResolutionNumberInput.value}
											onChange={onSetEcResolutionNumberInput}
											hasError={errors.includes(ecResolutionNumberInput.name)}
											required={ecResolutionNumberInput.required}
											ref={
												ecResolutionNumberInput.ref as RefObject<HTMLInputElement>
											}
											max={16}
											min={16}
											mask="CE-00000000-0000"
											placeholder="CE-AAAAMMJJ-XXXX"
											errors={errors}
											setError={onFixError}
											schema={/^CE-\d{8}-\d{4}$/}
										/>
									</>
								)}
							</>
						)}

						{/* Support for promoters */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_supportForPromoters_title
							}
						</h5>

						{/* Question 5 */}
						<RadioInput
							name={isRealEstateProjectInput.name}
							label={isRealEstateProjectInput.label}
							value={isRealEstateProjectInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isRealEstateProjectInput.name)}
							onChange={onSetIsRealEstateProjectInput}
							ref={isRealEstateProjectInput.ref as RefObject<HTMLInputElement>}
							required={isRealEstateProjectInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
							description={isRealEstateProjectInput.description}
						/>

						{isRealEstateProjectInput.value == radioListYesNo[0].value && (
							<>
								<MaskedTextInput
									id={projectNumberInput.name}
									label={projectNumberInput.label}
									value={projectNumberInput.value}
									onChange={onSetProjectNumberInput}
									hasError={errors.includes(projectNumberInput.name)}
									required={projectNumberInput.required}
									ref={projectNumberInput.ref as RefObject<HTMLInputElement>}
									max={12}
									min={12}
									mask="PD-0000-0000"
									placeholder="PD-AAAA-XXXX"
									errors={errors}
									setError={onFixError}
									schema={/^PD-\d{4}-\d{4}$/}
								/>
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_request_location}
						</h4>

						<div className={classes.adresseBlock}>
							<div className={pageUtils.classes.fieldsContainer}>
								<div>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>

								{buildingBatchNumberInput.value && (
									<div>
										<strong>{pageAssets?.lot_number_label}</strong>
										<br />
										{buildingBatchNumberInput.value}
									</div>
								)}
							</div>
						</div>

						{/* Question 7 */}
						<RadioInput
							name={isSameLocationInput.name}
							label={isSameLocationInput.label}
							value={isSameLocationInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSameLocationInput.name)}
							onChange={onSetIsSameLocationInput}
							ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
							required={isSameLocationInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* Question 8 */}
						{showAdresseBlock == true && (
							<>
								<AddressVoiePublicSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value
									}
									showButtonUserLocation={false}
									displayAddressDetails={true}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressLotNumber'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									addressOptions={
										// whatToDoInput.value ===
										// REQUEST_CONFIGS_KEYS
										// 	.whatToDoBuildingConstructionOrAdditionPermit
										// 	.request_new_construction
										// 	? radioListAddressOption.slice(0, 1)
										radioListAddressOption
									}
								/>

								{/* If your construction is new and you do not yet have a valid address, please enter a lot number instead. */}
								{/* <Notification
									text={
										pageAssets?.request_form_if_not_valid_address_notification_label
									}
									type="info"
									hasHtml
								/> */}
								<br />
							</>
						)}

						{isSameLocationInput.value == radioListYesNo[0].value &&
							isAddressOutsideLaval && (
								<Notification
									text={
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
									}
									type="warning"
									hasHtml
								/>
							)}

						{/* Question 9 */}
						<RadioInput
							name={isLocationOwnedInput.name}
							label={isLocationOwnedInput.label}
							value={isLocationOwnedInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isLocationOwnedInput.name)}
							onChange={onSetIsLocationOwnedInput}
							ref={isLocationOwnedInput.ref as RefObject<HTMLInputElement>}
							required={isLocationOwnedInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* ****** Owner inputs ****** */}
						{isLocationOwnedInput.value == radioListYesNo[1].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_ownerPowerOfAttorney_notification
									}
									type="info"
									hasHtml
								/>
								<div
									className={joinClasses([
										pageUtils.classes.fieldsContainer,
										classes.greyBackgroundBlock
									])}
								>
									<TextInput
										id={ownerFirstNameInput.name}
										label={ownerFirstNameInput.label}
										value={ownerFirstNameInput.value}
										onChange={onSetOwnerFirstNameInput}
										hasError={errors.includes(ownerFirstNameInput.name)}
										required={ownerFirstNameInput.required}
										ref={ownerFirstNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>
									<TextInput
										id={ownerLastNameInput.name}
										label={ownerLastNameInput.label}
										value={ownerLastNameInput.value}
										onChange={onSetOwnerLastNameInput}
										hasError={errors.includes(ownerLastNameInput.name)}
										required={ownerLastNameInput.required}
										ref={ownerLastNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>

									<TextInput
										id={ownerEmailInput.name}
										label={ownerEmailInput.label}
										value={ownerEmailInput.value}
										onChange={onSetOwnerEmailInput}
										hasError={errors?.includes(ownerEmailInput.name)}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets?.label_emailErrorText}
										required={ownerEmailInput.required}
										ref={ownerEmailInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>
									<MaskedTextInput
										id={ownerPhoneNumberInput.name}
										label={ownerPhoneNumberInput.label}
										value={ownerPhoneNumberInput.value}
										onChange={onSetOwnerPhoneNumberInput}
										hasError={errors?.includes(ownerPhoneNumberInput.name)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors ? errors : []}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required={ownerPhoneNumberInput.required}
										ref={
											ownerPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									subSteps={subSteps}
									maxFiles={firstQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_of_use}
						</h4>

						{/* <> */}
						{/* Question 10 */}
						<RadioInput
							name={mainUseOfBuildingInput.name}
							label={mainUseOfBuildingInput.label}
							value={mainUseOfBuildingInput.value}
							list={radioListMainUseEnlargementBuilding}
							direction={Direction.vertical}
							hasError={errors!.includes(mainUseOfBuildingInput.name)}
							onChange={onSetMainUseOfBuildingInput}
							ref={mainUseOfBuildingInput.ref as RefObject<HTMLInputElement>}
							required={mainUseOfBuildingInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 10.1 */}
						{mainUseOfBuildingInput.value.toString().includes(
							REQUEST_CONFIGS_KEYS.mainUseOfExistingBuilding.secondOption) && (
							<RadioInput
								name={mainBuildingHasHousingInput.name}
								label={mainBuildingHasHousingInput.label}
								value={mainBuildingHasHousingInput.value}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(mainBuildingHasHousingInput.name)}
								onChange={onSetExistingMainBuildingHasHousingInput}
								ref={
									mainBuildingHasHousingInput.ref as RefObject<HTMLInputElement>
								}
								required={mainBuildingHasHousingInput.required}
								hasBackgroundWrapper={true}
							/>
						)}

						{/* Question 9 */}
						<RadioInput
							name={structureTypeInput.name}
							label={structureTypeInput.label}
							value={structureTypeInput.value}
							list={radioListStructureType}
							direction={Direction.vertical}
							hasError={errors!.includes(structureTypeInput.name)}
							onChange={onSetStructureTypeInput}
							ref={structureTypeInput.ref as RefObject<HTMLInputElement>}
							required={structureTypeInput.required}
							hasBackgroundWrapper={true}
						/>

						<RadioInput
							name={isTheBuildingPartOfAPropertyInput.name}
							label={isTheBuildingPartOfAPropertyInput.label}
							value={isTheBuildingPartOfAPropertyInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isTheBuildingPartOfAPropertyInput.name
							)}
							onChange={onSetIsTheBuildingPartOfAPropertyInput}
							ref={
								isTheBuildingPartOfAPropertyInput.ref as RefObject<HTMLInputElement>
							}
							required={isTheBuildingPartOfAPropertyInput.required}
							hasBackgroundWrapper={true}
						/>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_building_informations}
						</h4>

						<>
							<CheckboxInput
								name={workTypeBuildingInput.name}
								label={workTypeBuildingInput.label}
								list={radioListWorkTypeEnlargementBuilding}
								value={`${workTypeBuildingInput.value}`}
								onChange={onSetWorkTypeBuildingInput}
								required={workTypeBuildingInput.required}
								hasError={errors.includes(workTypeBuildingInput.name)}
								ref={workTypeBuildingInput.ref as RefObject<HTMLInputElement>}
								errors={errors}
								shouldNotDivide
								hasBackgroundWrapper
								onFixError={onFixError}
								newCheckValue
							/>
							{/* <RadioInput
								name={workTypeBuildingInput.name}
								label={workTypeBuildingInput.label}
								value={workTypeBuildingInput.value}
								list={radioListWorkTypeEnlargementBuilding}
								direction={Direction.vertical}
								hasError={errors!.includes(workTypeBuildingInput.name)}
								onChange={onSetWorkTypeBuildingInput}
								ref={workTypeBuildingInput.ref as RefObject<HTMLInputElement>}
								required={workTypeBuildingInput.required}
								hasBackgroundWrapper={true}
							/> */}
							{workTypeBuildingInput.value.includes(
								REQUEST_CONFIGS_KEYS.workTypeBuilding.seventhOption
							) && (
								<TextInput
									id={Q11_1WorkTypeBuildingPrecisionInput.name}
									label={Q11_1WorkTypeBuildingPrecisionInput.label}
									value={Q11_1WorkTypeBuildingPrecisionInput.value}
									onChange={onSetQ11_1WorkTypeBuildingPrecisionInput}
									hasError={errors?.includes(
										Q11_1WorkTypeBuildingPrecisionInput.name
									)}
									type="text"
									onSetError={onFixError}
									schema={formUtils.MASKS.email}
									schemaErrorText={pageAssets?.label_TextErrorText}
									required={true}
									ref={
										Q11_1WorkTypeBuildingPrecisionInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							)}

							<div className={pageUtils.classes.fieldsContainer}>
								{/* Question 14 */}

								<>
									{renderMeasurementInput(
										workBuildingAreaInput,
										onSetWorkBuildingAreaInput,
										workBuildingAreaUnitInput,
										onSetBuildingAreaUnitInput,
										radioListMeasurement2Unit,
										12,
										true
									)}
								</>

								<>
									<TextInput
										id={currentNumberHomeInput.name}
										label={currentNumberHomeInput.label}
										value={currentNumberHomeInput.value}
										onChange={onSetCurrentNumberHomeInput}
										hasError={errors!.includes(currentNumberHomeInput.name)}
										required={currentNumberHomeInput.required}
										ref={
											currentNumberHomeInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={5}
										type="number"
										min="0"
										onKeyPress={pageUtils.onKeyPressIntegerInput}
									/>
								</>
							</div>

							<div className={pageUtils.classes.fieldsContainer}>
								<>
									<TextInput
										id={numberDwellingAddedInput.name}
										label={numberDwellingAddedInput.label}
										value={numberDwellingAddedInput.value}
										onChange={onSetNumberDwellingAddedInput}
										hasError={errors!.includes(numberDwellingAddedInput.name)}
										required={numberDwellingAddedInput.required}
										ref={
											numberDwellingAddedInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={3}
										type="number"
										min="0"
										onKeyPress={pageUtils.onKeyPressIntegerInput}
									/>
								</>
							</div>

							<RadioInput
								name={plumbingWorkInput.name}
								label={plumbingWorkInput.label}
								value={plumbingWorkInput.value}
								list={radioListYesNo}
								direction={Direction.vertical}
								hasError={errors!.includes(plumbingWorkInput.name)}
								required={plumbingWorkInput.required}
								ref={plumbingWorkInput.ref as RefObject<HTMLInputElement>}
								hasBackgroundWrapper={true}
								onChange={onSetPlumbingWorkInput}
								classWrapper={classes.reducedMarginBottom}
							/>

							{plumbingWorkInput.value == radioListYesNo[0].value && (
								<TextInput
									id={stabLevelInput.name}
									label={stabLevelInput.label}
									value={stabLevelInput.value}
									onChange={onSetStabLevelInput}
									hasError={errors!.includes(stabLevelInput.name)}
									required={stabLevelInput.required}
									ref={stabLevelInput.ref as RefObject<HTMLInputElement>}
								/>
							)}

							<br />
						</>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_information_on_the_work}
						</h4>

						<RadioInput
							name={isWorkDueToDisasterInput.name}
							label={isWorkDueToDisasterInput.label}
							value={isWorkDueToDisasterInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isWorkDueToDisasterInput.name)}
							required={isWorkDueToDisasterInput.required}
							ref={isWorkDueToDisasterInput.ref as RefObject<HTMLInputElement>}
							hasBackgroundWrapper={true}
							onChange={onSetIsWorkDueToDisasterInput}
							classWrapper={classes.reducedMarginBottom}
						/>

						{isWorkDueToDisasterInput.value == radioListYesNo[0].value && (
							<>
								<TextInput
									id={workDueToDisasterPrecisionInput.name}
									label={workDueToDisasterPrecisionInput.label}
									value={workDueToDisasterPrecisionInput.value}
									onChange={onSetWorkDueToDisasterPrecisionInput}
									hasError={errors!.includes(
										workDueToDisasterPrecisionInput.name
									)}
									required={workDueToDisasterPrecisionInput.required}
									ref={
										workDueToDisasterPrecisionInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						<MaskedThousandsSeparatorInput
							id={approximateCostOfWorkInput.name}
							label={approximateCostOfWorkInput.label}
							value={approximateCostOfWorkInput.value}
							onChange={onSetApproximateCostOfWorkInput}
							hasError={errors.includes(approximateCostOfWorkInput.name)}
							required={approximateCostOfWorkInput.required}
							ref={
								approximateCostOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							errors={errors}
							setError={onFixError}
							displayCurrency
						/>

						<RadioInput
							name={isTreeCuttingRequiredInput.name}
							label={isTreeCuttingRequiredInput.label}
							value={isTreeCuttingRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isTreeCuttingRequiredInput.name)}
							required={isTreeCuttingRequiredInput.required}
							ref={
								isTreeCuttingRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetIsTreeCuttingRequiredInput}
							description={isTreeCuttingRequiredInput.description}
						/>

						<TextAreaInput
							id={additionalInfoInput.name}
							label={additionalInfoInput.label}
							value={additionalInfoInput.value}
							onChange={onSetAdditionalInfoInput}
							hasError={errors!.includes(additionalInfoInput.name)}
							required={additionalInfoInput.required}
							ref={additionalInfoInput.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={100}
							hasDescriptionLabel={additionalInfoInput.description}
							displayDescriptionTop
							descriptionAlignment="left"
							rows={4}
						/>

						{/* Dates des travaux */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
							}
						</h5>

						<div className={pageUtils.classes.fieldsContainer}>
							<DateFilterInput
								id={startDateInput.name}
								label={startDateInput.label}
								value={startDateInput.value}
								onChange={onSetStartDateInput}
								hasError={errors!.includes(startDateInput.name)}
								required={startDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date()}
							/>

							<DateFilterInput
								id={endDateInput.name}
								label={endDateInput.label}
								value={endDateInput.value}
								onChange={onSetEndDateInput}
								hasError={errors!.includes(endDateInput.name)}
								required={endDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date(startDateInput.value + 'T12:00:00')}
								disabled={startDateInput.value == ''}
							/>
						</div>

						{/* Entrepreneur exécutant les travaux de construction. */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
							}
						</h5>

						<RadioInput
							name={whoWillBeInChargeOfWorkInput.name}
							label={whoWillBeInChargeOfWorkInput.label}
							value={whoWillBeInChargeOfWorkInput.value}
							list={
								requestAsInput.value ==
								radioListWhoWillBeInChargeOfWork[0].value
									? radioListWhoWillBeInChargeOfWork
									: radioListWhoWillBeInChargeOfWork.slice(1)
							}
							direction={Direction.vertical}
							hasError={errors!.includes(whoWillBeInChargeOfWorkInput.name)}
							required={whoWillBeInChargeOfWorkInput.required}
							ref={
								whoWillBeInChargeOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetWhoWillBeInChargeOfWorkInput}
						/>

						{/* <br /> */}
						{[
							radioListWhoWillBeInChargeOfWork[1].value,
							radioListWhoWillBeInChargeOfWork[2].value
						].includes(whoWillBeInChargeOfWorkInput.value) && (
							<div className={classes.checkbox2Container}>
								<span
									className={classes.checkboxWrapper}
									style={{ marginTop: '10px' }}
								>
									<input
										onClick={(e: any) =>
											onSetEntrepreneurUnknownInput(e.target.checked)
										}
										value={entrepreneurUnknownInput.value?.toString()}
										className={classes.checkbox2}
										checked={
											entrepreneurUnknownInput.value.toString() == 'true'
										}
										type="checkbox"
										name={entrepreneurUnknownInput.name}
										id={entrepreneurUnknownInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={entrepreneurUnknownInput.labelKey}
									>
										{entrepreneurUnknownInput.label}
									</label>
								</span>
							</div>
						)}

						<TextInput
							id={companyNameExecutingWorkInput.name}
							label={companyNameExecutingWorkInput.label}
							value={companyNameExecutingWorkInput.value}
							onChange={onSetCompanyNameExecutingWorkInput}
							hasError={errors!.includes(companyNameExecutingWorkInput.name)}
							required={companyNameExecutingWorkInput.required}
							ref={
								companyNameExecutingWorkInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={50}
						/>
						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={companyNEQNumberInput.name}
								label={companyNEQNumberInput.label}
								value={companyNEQNumberInput.value}
								onChange={onSetCompanyNEQNumberInput}
								hasError={errors!.includes(companyNEQNumberInput.name)}
								required={companyNEQNumberInput.required}
								ref={companyNEQNumberInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>

							<TextInput
								id={companyRBQNumberInput.name}
								label={companyRBQNumberInput.label}
								value={companyRBQNumberInput.value}
								onChange={onSetCompanyRBQNumberInput}
								hasError={errors!.includes(companyRBQNumberInput.name)}
								required={companyRBQNumberInput.required}
								ref={companyRBQNumberInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>
						</div>

						{/* Représentant(e) ou personne contact */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
							}
						</h6>

						<TextInput
							id={companyRepresentativeNameInput.name}
							label={companyRepresentativeNameInput.label}
							value={companyRepresentativeNameInput.value}
							onChange={onSetCompanyRepresentativeNameInput}
							hasError={errors!.includes(companyRepresentativeNameInput.name)}
							required={companyRepresentativeNameInput.required}
							ref={
								companyRepresentativeNameInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={100}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<MaskedTextInput
								id={companyRepresentativePhoneNumberInput.name}
								label={companyRepresentativePhoneNumberInput.label}
								value={companyRepresentativePhoneNumberInput.value}
								onChange={onSetCompanyRepresentativePhoneNumberInput}
								hasError={errors?.includes(
									companyRepresentativePhoneNumberInput.name
								)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={companyRepresentativePhoneNumberInput.required}
								ref={
									companyRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>

							<TextInput
								id={companyRepresentativeEmailInput.name}
								label={companyRepresentativeEmailInput.label}
								value={companyRepresentativeEmailInput.value}
								onChange={onSetCompanyRepresentativeEmailInput}
								hasError={errors!.includes(
									companyRepresentativeEmailInput.name
								)}
								required={companyRepresentativeEmailInput.required}
								ref={
									companyRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
								type="email"
								schema={formUtils.MASKS.email}
								schemaErrorText={pageAssets.label_emailErrorText}
								onSetError={onFixError}
							/>
						</div>

						{/* Adresse de l'entreprise */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
							}
						</h6>

						<TextInput
							id={companyAddressInput.name}
							label={companyAddressInput.label}
							value={companyAddressInput.value}
							onChange={onSetCompanyAddressInput}
							hasError={errors!.includes(companyAddressInput.name)}
							required={companyAddressInput.required}
							ref={companyAddressInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={50}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={companyCityInput.name}
								label={companyCityInput.label}
								value={companyCityInput.value}
								onChange={onSetCompanyCityInput}
								hasError={errors!.includes(companyCityInput.name)}
								required={companyCityInput.required}
								ref={companyCityInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>

							<MaskedTextInput
								id={companyPostalCodeInput.name}
								label={companyPostalCodeInput.label}
								value={companyPostalCodeInput.value}
								onChange={onSetCompanyPostalCodeInput}
								hasError={errors!.includes(companyPostalCodeInput.name)}
								required={companyPostalCodeInput.required}
								ref={companyPostalCodeInput.ref as RefObject<HTMLInputElement>}
								max={7}
								min={7}
								mask={formUtils.MASKS.postalCode}
								placeholder="A1A 1A1"
								errors={errors}
								setError={onFixError}
								schema={POSTAL_CODE_REGEX}
							/>
						</div>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{/* Travaux de branchement d'aqueduc et d'égouts */}
							{pageAssets?.request_form_branching_aqueduc_egouts}
						</h4>

						<RadioInput
							name={isWaterAndSewerConnectionRequiredInput.name}
							label={isWaterAndSewerConnectionRequiredInput.label}
							value={isWaterAndSewerConnectionRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors.includes(
								isWaterAndSewerConnectionRequiredInput.name
							)}
							required={isWaterAndSewerConnectionRequiredInput.required}
							ref={
								isWaterAndSewerConnectionRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetIsWaterAndSewerConnectionRequiredInput}
						/>

						{isWaterAndSewerConnectionRequiredInput?.value?.toString() ==
							radioListYesNo[0].value && (
							<>
								<CheckboxInput
									name={isPropertyConnectedToMunicipalConduitsInput.name}
									label={isPropertyConnectedToMunicipalConduitsInput.label}
									description={
										isPropertyConnectedToMunicipalConduitsInput.description
									}
									value={`${isPropertyConnectedToMunicipalConduitsInput.value}`}
									list={radioListIsPropertyConnectedToMunicipalConduits}
									onChange={onSetIsPropertyConnectedToMunicipalConduitsInput}
									required={
										isPropertyConnectedToMunicipalConduitsInput.required
									}
									hasError={errors.includes(
										isPropertyConnectedToMunicipalConduitsInput.name
									)}
									ref={
										isPropertyConnectedToMunicipalConduitsInput.ref as RefObject<HTMLInputElement>
									}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
									disabledValues={disabledMunicipalConduits}
								/>

								<TextAreaInput
									id={Q42AdditionalInfoInput.name}
									label={Q42AdditionalInfoInput.label}
									value={Q42AdditionalInfoInput.value}
									onChange={onSetQ42AdditionalInfoInput}
									hasError={errors.includes(Q42AdditionalInfoInput.name)}
									required={Q42AdditionalInfoInput.required}
									ref={
										Q42AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
									}
									maxCharacters={100}
									rows={4}
								/>

								<CheckboxInput
									name={plannedWorkInput.name}
									label={plannedWorkInput.label}
									description={plannedWorkInput.description}
									value={`${plannedWorkInput.value}`}
									list={listPlannedWork}
									onChange={onSetPlannedWorkInput}
									required={plannedWorkInput.required}
									hasError={errors.includes(plannedWorkInput.name)}
									ref={plannedWorkInput.ref as RefObject<HTMLInputElement>}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
								/>

								{plannedWorkInput.value.includes(
									listPlannedWork.slice(-1)[0].value
								) && (
									<TextAreaInput
										id={additionalInfoInput2.name}
										label={additionalInfoInput2.label}
										value={additionalInfoInput2.value}
										onChange={onSetAdditionalInfoInput2}
										hasError={errors.includes(additionalInfoInput2.name)}
										required={additionalInfoInput2.required}
										ref={
											additionalInfoInput2.ref as RefObject<HTMLTextAreaElement>
										}
										maxCharacters={100}
										rows={4}
									/>
								)}

								{isEntrepreneurFieldsRequired(plannedWorkInput.value) && (
									<>
										{/* Entrepreneur (branchement d'aqueduc et d'égout ou génie civil) */}
										<h5 className={pageUtils.classes.h5}>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_branchement_aqueduc_egouts_ou_ingenieur_civil
											}
										</h5>

										<br />
										<div className={classes.checkbox2Container}>
											<span
												className={classes.checkboxWrapper}
												style={{ marginTop: '10px' }}
											>
												<input
													onClick={(e: any) =>
														onSetEntrepreneurUnknownInput2(e.target.checked)
													}
													value={entrepreneurUnknownInput2.value?.toString()}
													className={classes.checkbox2}
													checked={
														entrepreneurUnknownInput2.value.toString() == 'true'
													}
													type="checkbox"
													name={entrepreneurUnknownInput2.name}
													id={entrepreneurUnknownInput2.labelKey}
												/>
												<label
													className={classes.checkbox2Label}
													htmlFor={entrepreneurUnknownInput2.labelKey}
												>
													{entrepreneurUnknownInput2.label}
												</label>
											</span>
										</div>

										<TextInput
											id={entrepreneurNameInput.name}
											label={entrepreneurNameInput.label}
											value={entrepreneurNameInput.value}
											onChange={onSetEntrepreneurNameInput}
											hasError={errors.includes(entrepreneurNameInput.name)}
											required={entrepreneurNameInput.required}
											ref={
												entrepreneurNameInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<TextInput
											id={entrepreneurRBQNumberInput.name}
											label={entrepreneurRBQNumberInput.label}
											value={entrepreneurRBQNumberInput.value}
											onChange={onSetEntrepreneurRBQNumberInput}
											hasError={errors.includes(
												entrepreneurRBQNumberInput.name
											)}
											required={entrepreneurRBQNumberInput.required}
											ref={
												entrepreneurRBQNumberInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										{/* Représentant(e) ou personne contact. */}
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
											}
										</h6>

										<TextInput
											id={entrepreneurRepresentativeNameInput.name}
											label={entrepreneurRepresentativeNameInput.label}
											value={entrepreneurRepresentativeNameInput.value}
											onChange={onSetEntrepreneurRepresentativeNameInput}
											hasError={errors.includes(
												entrepreneurRepresentativeNameInput.name
											)}
											required={entrepreneurRepresentativeNameInput.required}
											ref={
												entrepreneurRepresentativeNameInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<div className={pageUtils.classes.fieldsContainer}>
											<MaskedTextInput
												id={entrepreneurRepresentativePhoneNumberInput.name}
												label={entrepreneurRepresentativePhoneNumberInput.label}
												value={entrepreneurRepresentativePhoneNumberInput.value}
												onChange={
													onSetEntrepreneurRepresentativePhoneNumberInput
												}
												hasError={errors?.includes(
													entrepreneurRepresentativePhoneNumberInput.name
												)}
												type="tel"
												min={10}
												max={14}
												mask={formUtils.MASKS.phoneNumber}
												placeholder={formUtils.MASKS.phoneNumber}
												errors={errors ? errors : []}
												setError={onFixError}
												schema={PHONE_NUMBER_REGEX}
												required={
													entrepreneurRepresentativePhoneNumberInput.required
												}
												ref={
													entrepreneurRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
												}
											/>

											<TextInput
												id={entrepreneurRepresentativeEmailInput.name}
												label={entrepreneurRepresentativeEmailInput.label}
												value={entrepreneurRepresentativeEmailInput.value}
												onChange={onSetEntrepreneurRepresentativeEmailInput}
												hasError={errors.includes(
													entrepreneurRepresentativeEmailInput.name
												)}
												required={entrepreneurRepresentativeEmailInput.required}
												ref={
													entrepreneurRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
												}
												schema={formUtils.MASKS.email}
												schemaErrorText={pageAssets.label_emailErrorText}
												maxCharacters={50}
												type="email"
												onSetError={onFixError}
											/>
										</div>

										{/* Adresse de l'entreprise */}
										<h6
											className={pageUtils.classes.h6}
											style={{ marginTop: '20px' }}
										>
											{
												pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
											}
										</h6>

										<TextInput
											id={entrepreneurRepresentativeAddressInput.name}
											label={entrepreneurRepresentativeAddressInput.label}
											value={entrepreneurRepresentativeAddressInput.value}
											onChange={onSetEntrepreneurRepresentativeAddressInput}
											hasError={errors.includes(
												entrepreneurRepresentativeAddressInput.name
											)}
											required={entrepreneurRepresentativeAddressInput.required}
											ref={
												entrepreneurRepresentativeAddressInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={50}
										/>

										<div className={pageUtils.classes.fieldsContainer}>
											<TextInput
												id={entrepreneurRepresentativeCityInput.name}
												label={entrepreneurRepresentativeCityInput.label}
												value={entrepreneurRepresentativeCityInput.value}
												onChange={onSetEntrepreneurRepresentativeCityInput}
												hasError={errors.includes(
													entrepreneurRepresentativeCityInput.name
												)}
												required={entrepreneurRepresentativeCityInput.required}
												ref={
													entrepreneurRepresentativeCityInput.ref as RefObject<HTMLInputElement>
												}
												maxCharacters={50}
											/>

											<MaskedTextInput
												id={entrepreneurRepresentativePostalCodeInput.name}
												label={entrepreneurRepresentativePostalCodeInput.label}
												value={entrepreneurRepresentativePostalCodeInput.value}
												onChange={
													onSetEntrepreneurRepresentativePostalCodeInput
												}
												hasError={errors.includes(
													entrepreneurRepresentativePostalCodeInput.name
												)}
												required={
													entrepreneurRepresentativePostalCodeInput.required
												}
												ref={
													entrepreneurRepresentativePostalCodeInput.ref as RefObject<HTMLInputElement>
												}
												max={7}
												min={7}
												mask={formUtils.MASKS.postalCode}
												placeholder="A1A 1A1"
												errors={errors}
												setError={onFixError}
												schema={POSTAL_CODE_REGEX}
											/>
										</div>
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>

						<span className={pageUtils.classes.fileInfo}>
							{pageAssets?.page_requestInfo_inputFileDesc}
						</span>

						<>
							{/* false stands for isSummary */}
							{renderAllRequestFiles('required', false)}
							{renderAllRequestFiles('optional', false)}
						</>
					</section>
				</ReactCollapse>
			)}
		</>
	)
}

export default BuildingEnlargementPermitForm
